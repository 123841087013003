import { Box } from '@mui/material';
import { airshipCategories } from '../data/dataHelpers';
import { ISettingsAirshipCategories } from '../user/userSlice';
import CalculateCategory from './CalculateCategory';

export type OpenSettingsModalFn = (newTab?: ISettingsAirshipCategories) => void;

const CalculateAll = () => {
  return (
    <Box display="flex" gap={2} flexWrap="wrap">
      {airshipCategories.map((c) =>
        c.name ? <CalculateCategory key={c.key} category={c.key} /> : null
      )}
    </Box>
  );
};

export default CalculateAll;
