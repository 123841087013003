import React, { useMemo } from 'react';
import { MaterialReactTable, MRT_Cell } from 'material-react-table';
import type { MRT_ColumnDef } from 'material-react-table';
import { Avatar, Box, useMediaQuery, useTheme } from '@mui/material';

import { useAppSelector, useGetBlueprints } from '../../app/hooks';
import { Blueprint, blueprintAdapter } from '../data/dataSlice';
import { MemoizedCollectionTableCellLearned } from '../collection/CollectionTableCellLearned';
import { MemoizedCollectionTableCellMastered } from '../collection/CollectionTableCellMastered';
import { MemoizedCollectionTableCollected } from '../collection/CollectionTableCollected';
import { collectionSelectors } from '../user/userSlice';
import { store } from '../../app/store';
import BlueprintTableSwitchVirtualization from './BlueprintTableSwitchVirtualization';
import BlueprintTableColumnFilters from './BlueprintTableColumnFilters';
import AssetBox from '../asset/AssetBox';
import { useOpenBlueprintModal } from '../core/hooks';
import { MRTFixedCellSize } from '../core/coreHelpers';

type Props = {
  virtualization?: boolean;
};

const showNumber = ({ cell }: { cell: MRT_Cell<Blueprint> }) => {
  const value = cell.getValue<number>();
  return <>{value.toLocaleString()}</>;
};

const showPercentage = ({ cell }: { cell: MRT_Cell<Blueprint> }) => {
  const value = cell.getValue<number>();
  return <>{Math.floor(value * 100)}%</>;
};

const accessorFn = (key: keyof Blueprint) => (row: Blueprint) => row[key] ?? 0;

const BlueprintsTable: React.FC<Props> = ({ virtualization = false }) => {
  const blueprints = useGetBlueprints();
  const openModal = useOpenBlueprintModal();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const bpTypes = useAppSelector((state) => state.data.blueprintTypes);
  const bpClasses = useAppSelector((state) => state.data.blueprintClasses);

  const itemTypes = useMemo(
    () =>
      bpTypes.map((bpt) => ({
        text: `${bpt.name} (${bpt.count})`,
        value: bpt.name,
      })),
    [bpTypes]
  );

  const itemClasses = useMemo(
    () =>
      bpClasses.map((bpc) => ({
        text: `${bpc.name} (${bpc.count})`,
        value: bpc.name,
      })),
    [bpClasses]
  );

  const columns = useMemo<MRT_ColumnDef<Blueprint>[]>(
    () => [
      {
        id: 'asset',
        header: 'Icon',
        accessorKey: 'asset',
        Cell: ({ cell }) => {
          const asset = cell.getValue<string>();
          if (!asset) return null;
          return (
            <Avatar
              variant="square"
              sx={{ '&': { backgroundColor: 'transparent' } }}
            >
              <AssetBox width="100%" height="100%" src={asset} />
            </Avatar>
          );
        },
        enableColumnFilter: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableGlobalFilter: false,
        size: 65,
        ...MRTFixedCellSize(),
        Header: false,
        enableSorting: false,
        // enablePinning: true,
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'name',
        enableColumnDragging: false,
      },
      {
        id: 'class',
        header: 'Class',
        accessorKey: 'class',
        filterVariant: 'select',
        filterSelectOptions: itemClasses,
        enableColumnFilterModes: false,
        enableColumnDragging: false,
        // size: 120,
      },
      {
        id: 'type',
        header: 'Type',
        accessorKey: 'type',
        filterVariant: 'select',
        filterSelectOptions: itemTypes,
        enableColumnFilterModes: false,
        enableColumnDragging: false,
        size: 140,
        ...MRTFixedCellSize(),
      },
      {
        id: 'tier',
        header: 'T',
        accessorKey: 'tier',
        columnFilterModeOptions: [
          'betweenInclusive',
          'between',
          'greaterThan',
          'greaterThanOrEqualTo',
          'lessThan',
          'lessThanOrEqualTo',
        ],
        filterFn: 'betweenInclusive',
        enableColumnDragging: false,
        size: 70,
        ...MRTFixedCellSize(),
      },
      {
        id: 'airshipCategory',
        header: 'Airship Category',
        accessorKey: 'airshipCategory',
        enableColumnDragging: false,
      },
      {
        id: 'airshipPower',
        header: 'Airship Power',
        accessorFn: accessorFn('airshipPower'),
        Cell: showNumber,
        enableColumnDragging: false,
      },
      {
        id: 'builtInElement',
        header: 'Builtin Element',
        accessorKey: 'builtInElement',
        enableColumnDragging: false,
      },
      {
        id: 'elementAffinity',
        header: 'Element Affinity',
        accessorKey: 'elementAffinity',
        enableColumnDragging: false,
      },
      {
        id: 'builtInSpirit',
        header: 'Builtin Spirit',
        accessorKey: 'builtInSpirit',
        enableColumnDragging: false,
      },
      {
        id: 'spiritAffinity',
        header: 'Spirit Affinity',
        accessorKey: 'spiritAffinity',
        enableColumnDragging: false,
      },
      {
        id: 'atk',
        header: 'Attack',
        accessorFn: accessorFn('atk'),
        Cell: showNumber,
        enableColumnDragging: false,
      },
      {
        id: 'def',
        header: 'Defense',
        accessorFn: accessorFn('def'),
        Cell: showNumber,
        enableColumnDragging: false,
      },
      {
        id: 'hp',
        header: 'Health',
        accessorFn: accessorFn('hp'),
        Cell: showNumber,
        enableColumnDragging: false,
      },
      {
        id: 'eva',
        header: 'Evasion',
        accessorFn: accessorFn('eva'),
        Cell: showPercentage,
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'crit',
        header: 'Crit',
        accessorFn: accessorFn('crit'),
        Cell: showPercentage,
        sortUndefinedLast: true,
        enableColumnDragging: false,
      },
      {
        id: 'wood',
        header: 'Wood',
        accessorFn: accessorFn('wood'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'iron',
        header: 'Iron',
        accessorFn: accessorFn('iron'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'leather',
        header: 'Leather',
        accessorFn: accessorFn('leather'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'herbs',
        header: 'Herbs',
        accessorFn: accessorFn('herbs'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'steel',
        header: 'Steel',
        accessorFn: accessorFn('steel'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'ironwood',
        header: 'Ironwood',
        accessorFn: accessorFn('ironwood'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'fabric',
        header: 'Fabric',
        accessorFn: accessorFn('fabric'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'oils',
        header: 'Oil',
        accessorFn: accessorFn('oils'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'mana',
        header: 'Ether',
        accessorFn: accessorFn('mana'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'gems',
        header: 'Jewel',
        accessorFn: accessorFn('gems'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'essence',
        header: 'Essence',
        accessorFn: accessorFn('essence'),
        sortUndefinedLast: -1,
        enableColumnDragging: false,
      },
      {
        id: 'merchantXp',
        header: 'Merchant XP',
        accessorFn: accessorFn('merchantXp'),
        Cell: showNumber,
        enableColumnDragging: false,
      },
      {
        id: 'unlockPrerequisite',
        header: 'Unlock Prerequisite',
        accessorKey: 'unlockPrerequisite',
        enableColumnDragging: false,
      },
      {
        id: 'learned',
        header: 'Learned',
        accessorFn: (bp) => {
          const id = blueprintAdapter.selectId(bp);
          const collection = collectionSelectors.selectById(
            store.getState(),
            id
          );
          return collection?.learned ?? false;
        },
        Cell: ({ row }) => {
          const id = blueprintAdapter.selectId(row.original);
          return <MemoizedCollectionTableCellLearned id={id} />;
        },
        filterVariant: 'select',
        filterSelectOptions: [
          { text: 'Learned', value: 'true' },
          { text: 'Not learned', value: 'false' },
        ],
        enableColumnFilterModes: false,
        enableColumnDragging: false,
        size: 120,
        ...MRTFixedCellSize(),
      },
      {
        id: 'mastered',
        header: 'Mastered',
        accessorFn: (bp) => {
          const collection = collectionSelectors.selectById(
            store.getState(),
            bp.id
          );
          return collection?.mastered ?? false;
        },
        Cell: ({ row }) => (
          <MemoizedCollectionTableCellMastered id={row.original.id} />
        ),
        filterVariant: 'select',
        filterSelectOptions: [
          { text: 'Mastered', value: 'true' },
          { text: 'Not mastered', value: 'false' },
        ],
        enableColumnFilterModes: false,
        enableColumnDragging: false,
        size: 120,
        ...MRTFixedCellSize(),
      },
      {
        id: 'collected',
        header: 'Collection',
        Cell: ({ row }) => (
          <MemoizedCollectionTableCollected id={row.original.id} />
        ),
        enableColumnDragging: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 226,
        ...MRTFixedCellSize(),
      },
    ],
    [itemClasses, itemTypes]
  );

  if (!blueprints.length) return null;

  return (
    <>
      <MaterialReactTable
        enablePinning
        columns={columns}
        data={blueprints}
        layoutMode="grid"
        memoMode="cells"
        enableColumnOrdering
        enableColumnFilterModes
        columnFilterDisplayMode="popover"
        enableRowVirtualization={virtualization}
        enablePagination={!virtualization}
        enableDensityToggle={false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            openModal(row.original.id);
          },
          sx: { cursor: 'pointer' },
        })}
        renderBottomToolbarCustomActions={() => (
          <Box display="flex" gap="10px">
            <BlueprintTableSwitchVirtualization />
          </Box>
        )}
        muiPaginationProps={{
          rowsPerPageOptions: [10, 15, 25, 50],
        }}
        initialState={{
          density: 'compact',
          columnPinning: { left: matches ? ['asset'] : ['asset', 'name'] },
          columnVisibility: {
            class: false,
            airshipCategory: false,
            airshipPower: false,
            builtInElement: false,
            elementAffinity: false,
            builtInSpirit: false,
            spiritAffinity: false,
            unlockPrerequisite: false,
            merchantXp: false,
            atk: false,
            def: false,
            hp: false,
            eva: false,
            crit: false,
            wood: false,
            iron: false,
            leather: false,
            herbs: false,
            steel: false,
            ironwood: false,
            fabric: false,
            oils: false,
            mana: false,
            gems: false,
            essence: false,
          },
          pagination: { pageIndex: 0, pageSize: 50 },
        }}
        enableStickyHeader
        muiTableContainerProps={{
          sx: {
            maxHeight: 'calc(100vh - 240px)',
            maxWidth: 'calc(100vw - 48px)',
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <BlueprintTableColumnFilters table={table} />
        )}
        muiTopToolbarProps={{ sx: { borderBottom: 1, borderColor: 'divider' } }}
      />
    </>
  );
};

export default BlueprintsTable;
