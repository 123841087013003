import { useAppSelector } from '.';
import {
  ISettingsTypeFilter,
  ISettingsTypeFilters,
} from '../../features/user/userSlice';
import { useGetMaxMarketTier } from './useGetMaxMarketTier';

export const useBuildDefaultTypeFilterSettings = () => {
  const merchantLevel = useAppSelector((state) =>
    state.data.merchantLevels.find((ml) => ml.level === state.user.data.level)
  );
  const marketTier = merchantLevel?.tierUnlock || 1;
  const maxTier = useGetMaxMarketTier();

  return {
    learnedTier: [1, maxTier],
    includeNotlearned: true,
    notlearnedTier: [1, marketTier],
  } as ISettingsTypeFilter;
};

const typeFilters = ['item', 'element', 'spirit', 'all'];
export const useBuildDefaultTypeFiltersSettings = () => {
  const defaultTypeFilter = useBuildDefaultTypeFilterSettings();
  const types = typeFilters.reduce((ta, tc) => {
    ta[tc] = { ...defaultTypeFilter };
    return ta;
  }, {} as any) as ISettingsTypeFilters;

  return { shareSettings: false, type: types, pageSize: 10 };
};
