import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const BlueprintTableSwitchVirtualization = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const virtual = location.pathname === '/virtualized';

  const path = virtual ? '/' : '/virtualized';
  const label = virtual ? 'Paginated' : 'Endless';

  return (
    <Button
      color="warning"
      variant="contained"
      onClick={() => {
        navigate(path);
      }}
    >
      {label}
    </Button>
  );
};

export default BlueprintTableSwitchVirtualization;
