import { EntityId } from '@reduxjs/toolkit';

import { useAppDispatch } from '../../app/hooks';
import { collectionToggleProp, CollectionToggleProp } from '../user/userSlice';

export const useHandleToggleProp = (
  id: EntityId,
  prop: CollectionToggleProp
) => {
  const dispatch = useAppDispatch();
  return () => dispatch(collectionToggleProp(id, prop));
};
