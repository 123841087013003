import { EntityId } from '@reduxjs/toolkit';
import { NonUndefined } from 'tsdef';
import {
  CollectedQualityTypes,
  Collection,
  CollectionToggleProp,
  IImportData,
  IUserWorker,
  UserInitialState,
} from './userSlice';

export const test = '';

export function togglePropCollectionAPI(
  collection: Collection,
  prop: CollectionToggleProp
) {
  return new Promise<Collection>((resolve) => {
    resolve({
      ...collection,
      ...{ [prop]: !collection[prop] },
    });
  });
}

export function toggleCollectionQualityCollectedAPI(
  collection: Collection,
  quality: CollectedQualityTypes
) {
  return new Promise<Collection>((resolve) => {
    resolve({
      ...collection,
      collected: {
        ...collection.collected,
        [quality]: !collection.collected[quality],
      },
    });
  });
}

type AirshipSettings = NonUndefined<UserInitialState['airship']>;
export function saveAirshipSettingsAPI(settings: AirshipSettings) {
  return new Promise<AirshipSettings>((resolve) => {
    resolve(settings);
  });
}

type IKingBaitSettings = NonUndefined<UserInitialState['kingBait']>['settings'];
export function saveKingbaitSettingsAPI(settings: IKingBaitSettings) {
  return new Promise<IKingBaitSettings>((resolve) => {
    resolve(settings);
  });
}

type IKingBaitQuality = NonUndefined<UserInitialState['kingBait']>['quality'];
export const saveKingBaitQualityAPI = (quality: IKingBaitQuality) => {
  return new Promise<IKingBaitQuality>((resolve) => {
    resolve(quality);
  });
};

export const toggleWorkerUnlockedAPI = (workerId: EntityId) => {
  return new Promise<EntityId>((resolve) => {
    resolve(workerId);
  });
};

export const saveUserWorkerAPI = (worker: IUserWorker) => {
  return new Promise<IUserWorker>((resolve) => {
    resolve(worker);
  });
};

export const importDataAPI = (data: IImportData) => {
  return new Promise<IImportData>((resolve) => {
    resolve(data);
  });
};

export const setThemeModeAPI = (mode: UserInitialState['mode']) => {
  return new Promise<UserInitialState['mode']>((resolve) => {
    resolve(mode);
  });
};

/*
  Collection Score Per Tier	
  Donating an item will award this collection score based on tier.	
      
  Item Tier   Score
      1	        1
      2	        1
      3	        1
      4	        2
      5	        2
      6	        3
      7	        3
      8	        4
      9         4
     10	        5
     11	        7
     12	       10
      
  Quality Multipliers	
  An item's quality will multiply its collection score.	
      
  Quality   Multiplier
  Normal	      1
  Superior	    1
  Flawless	    2
  Epic	        3
  Legendary	    5
  // */
