import { Badge, BadgeProps } from '@mui/material';
import { useGetCurrentMode } from '../../app/hooks/useGetCurrentMode';
import AssetBox, { AssetBoxProps } from './AssetBox';

export type AssetBadgeProps = AssetBoxProps & {
  showBadge?: boolean;
  badgeProps?: BadgeProps;
};

type AssetBadgePropsChildren = AssetBadgeProps & {
  children: React.ReactNode;
};

const AssetBadge: React.FC<AssetBadgePropsChildren> = ({
  showBadge = true,
  children,
  badgeProps,
  width = '24px',
  height = '24px',
  ...assetBoxProps
}) => {
  const mode = useGetCurrentMode();

  const shadowColor = mode === 'light' ? '255, 255, 255' : '75, 75, 75';
  if (!showBadge) return <>{children}</>;
  return (
    <Badge
      overlap="circular"
      //   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <AssetBox
          width={width}
          height={height}
          sx={{
            zIndex: 100,
            filter:
              `drop-shadow(0px 0px 1px rgb(${shadowColor}))` +
              `drop-shadow(0px 1px 1px rgb(${shadowColor}))` +
              `drop-shadow(1px 0px 1px rgb(${shadowColor}))` +
              `drop-shadow(1px 1px 1px rgb(${shadowColor}))` +
              `drop-shadow(0 0 2px rgba(${shadowColor}, 0.3))` +
              `drop-shadow(0 0 2px rgba(${shadowColor}, 0.3))`,
          }}
          {...assetBoxProps}
        />
      }
      {...badgeProps}
    >
      {children}
    </Badge>
  );
};

export default AssetBadge;
