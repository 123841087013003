import { Avatar } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import { useGetBlueprintWithCollection } from '../../app/hooks';
import AssetBadge from '../asset/AssetBadge';
import AssetBox from '../asset/AssetBox';
import { useGetAssetBadgeProps } from './hooks';

interface Props {
  id: EntityId;
}

const CalculateCategoryItemIcon: React.FC<Props> = ({ id }) => {
  const blueprint = useGetBlueprintWithCollection(id);
  const assetBadgeProps = useGetAssetBadgeProps(blueprint);

  if (!blueprint) return null;

  return (
    <AssetBadge {...assetBadgeProps}>
      <Avatar variant="square" sx={{ '&': { backgroundColor: 'transparent' } }}>
        <AssetBox width="100%" height="100%" src={blueprint.asset} />
      </Avatar>
    </AssetBadge>
  );
};

export default CalculateCategoryItemIcon;
