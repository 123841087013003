import { isEqual } from 'lodash';
import {
  Blueprint,
  blueprintSelectors,
  BlueprintWithCollection,
} from '../../features/data/dataSlice';
import {
  collectionSelectors,
  getNewCollection,
} from '../../features/user/userSlice';
import { useAppSelector } from './storeHooks';

interface IUseGetBlueprintsWithCollectionFn {
  (filter?: (bp: Blueprint) => boolean): BlueprintWithCollection[];
}

export const useGetBlueprintsWithCollection: IUseGetBlueprintsWithCollectionFn =
  (bpFilter = () => true) => {
    return useAppSelector((state) => {
      return blueprintSelectors
        .selectAll(state)
        .filter(bpFilter)
        .map((bp) => {
          const collection = collectionSelectors.selectById(state, bp.id);
          return {
            ...bp,
            ...(collection ?? getNewCollection(bp.id)),
          };
        });
    }, isEqual);
  };
