import { Divider, Drawer, IconButton, styled, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppMenu from './AppMenu';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

type Props = {
  open: boolean;
  drawerwidth: number;
  handleDrawerClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const AppDrawer: React.FC<Props> = ({
  open,
  drawerwidth,
  handleDrawerClose,
}) => {
  const theme = useTheme();

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: drawerwidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerwidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <AppMenu />
    </Drawer>
  );
};

export default AppDrawer;
