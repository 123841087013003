import { Box, MenuItem } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import { useGetAssetBadgeProps } from '../airship/hooks';
import AssetBox from '../asset/AssetBox';
import { BlueprintWithCollection } from '../data/dataSlice';
import { useGetSelectedBlueprintWithCollection } from './hooks';

interface Props {
  enchant: BlueprintWithCollection;
  id: EntityId;
  onSelect: (id: EntityId) => void;
  selectedId: EntityId | undefined;
}

const BlueprintModalSelectEnchantmentPopoverMenuItem: React.FC<Props> = ({
  id,
  enchant,
  selectedId,
  onSelect,
}) => {
  const blueprint = useGetSelectedBlueprintWithCollection('blueprint');
  const { showBadge, ...assetBoxProps } = useGetAssetBadgeProps(enchant);

  if (!blueprint) return null;

  let isAffinity: boolean;
  if (enchant.type === 'Element') {
    isAffinity =
      blueprint.elementAffinity === enchant.elementAffinity ||
      blueprint.elementAffinity === 'All';
  } else {
    isAffinity =
      blueprint.spiritAffinity?.split(' ')[0] ===
      enchant.spiritAffinity?.split(' ')[0];
  }

  return (
    <MenuItem
      key={id}
      value={id}
      onClick={() => onSelect(id)}
      selected={id === selectedId}
    >
      <Box display="flex" gap={1} justifyContent="space-between" width="100%">
        <Box display="flex" gap={1}>
          <AssetBox
            src={enchant.assetEnchantment}
            width="24px"
            height="24px"
            shadow={isAffinity ? 'affinity' : false}
          />
          <Box display="flex" gap={1}>
            <Box
              sx={{ width: '28px', fontWeight: 'bold', textAlign: 'center' }}
            >
              T{enchant.tier}
            </Box>
            <Box>{enchant.name}</Box>
          </Box>
        </Box>
        {showBadge ? (
          <AssetBox {...assetBoxProps} width="24px" height="24px" />
        ) : null}
      </Box>
    </MenuItem>
  );
};

export default BlueprintModalSelectEnchantmentPopoverMenuItem;
