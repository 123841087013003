import { useMemo } from 'react';
import { blueprintQualities } from '../../features/collection/CollectionTableCollected';
import {
  Blueprint,
  BlueprintWithCollection,
} from '../../features/data/dataSlice';
import { CollectedQualityTypes } from '../../features/user/userSlice';

type CalculateBlueprintPropFn = (
  blueprint?: BlueprintWithCollection,
  quality?: CollectedQualityTypes,
  element?: Blueprint,
  spirit?: Blueprint
) => number;

interface ICalculatePropPowerFn {
  (
    prop: 'atk' | 'def' | 'hp',
    qm: number,
    blueprint: Blueprint,
    element?: Blueprint,
    spirit?: Blueprint
  ): number;
}

const calcPower: ICalculatePropPowerFn = (
  prop,
  qm,
  blueprint,
  element?,
  spirit?
) => {
  const elementAffinityBonus =
    blueprint.elementAffinity === element?.elementAffinity ||
    blueprint.elementAffinity === 'All'
      ? 1.5
      : 1;
  const spiritAffinityBonus =
    blueprint.spiritAffinity?.split(' ')[0] ===
    spirit?.spiritAffinity?.split(' ')[0]
      ? 1.5
      : 1;

  let propValue = blueprint[prop] ?? 0;
  if (propValue > 0) {
    const elementBonus = Math.min(
      Math.floor((element?.[prop] ?? 0) * elementAffinityBonus),
      propValue
    );
    const spiritBonus = Math.min(
      Math.floor((spirit?.[prop] ?? 0) * spiritAffinityBonus),
      propValue
    );
    return Math.round(propValue * qm) + elementBonus + spiritBonus;
  }
  return propValue;
};

interface ICalculateValueFn {
  (
    qm: number,
    blueprint: BlueprintWithCollection,
    element?: Blueprint,
    spirit?: Blueprint
  ): number;
}
const calculateValue: ICalculateValueFn = (
  qm,
  blueprint,
  element?,
  spirit?
) => {
  const elementAffinityBonus =
    blueprint.elementAffinity === element?.elementAffinity ? 1.1 : 1;
  const spiritAffinityBonus =
    blueprint.spiritAffinity?.split(' ')[0] ===
    spirit?.spiritAffinity?.split(' ')[0]
      ? 1.1
      : 1;

  const milestoneBonus = blueprint.mastered
    ? blueprint.valueMilestoneMultiplier ?? 1
    : 1;

  const bpValue = (blueprint.value ?? 0) * milestoneBonus;
  // return qm;
  return (
    (bpValue +
      Math.min(bpValue, (element?.value ?? 0) * elementAffinityBonus) +
      Math.min(bpValue, (spirit?.value ?? 0) * spiritAffinityBonus)) *
    qm
  );
};

interface IUseCalculateBlueprintPropertyFn {
  (prop: 'airship' | 'value'): CalculateBlueprintPropFn;
}

const useCalculateBlueprintProperty: IUseCalculateBlueprintPropertyFn = (
  prop
) => {
  const calculatBlueprintProp = useMemo<CalculateBlueprintPropFn>(
    () =>
      (
        blueprint?: BlueprintWithCollection,
        quality?: string,
        element?: Blueprint,
        spirit?: Blueprint
      ) => {
        if (!blueprint) return 0;
        const bpq = blueprintQualities.find((bq) => bq.name === quality);
        const qm = bpq?.multiplier ?? 1;
        const vqm = bpq?.valueMultiplier ?? 1;

        let propValue = 0;

        switch (prop) {
          case 'airship':
            propValue += calcPower('atk', qm, blueprint, element, spirit) * 0.8;
            propValue += calcPower('def', qm, blueprint, element, spirit) * 1.2;
            propValue += calcPower('hp', qm, blueprint, element, spirit) * 5;
            propValue *= 1 + (blueprint.eva ? blueprint.eva : 0) * 10;
            propValue *= 1 + (blueprint.crit ? blueprint.crit : 0) * 10;
            propValue = Math.round(propValue);
            break;
          case 'value':
            propValue = calculateValue(vqm, blueprint, element, spirit);
            switch (true) {
              case propValue > 1000000:
                propValue = Math.round(propValue / 50000) * 50000;
                break;
              case propValue > 100000:
                propValue = Math.round(propValue / 5000) * 5000;
                break;
              case propValue > 10000:
                propValue = Math.round(propValue / 500) * 500;
                break;
              case propValue > 1000:
                propValue = Math.round(propValue / 50) * 50;
                break;
              case propValue > 50:
                propValue = Math.round(propValue / 10) * 10;
                break;
              case propValue > 10:
                propValue = Math.round(propValue / 5) * 5;
                break;
            }
            break;
        }

        return propValue || 0;
      },
    [prop]
  );
  return calculatBlueprintProp;
};

export { useCalculateBlueprintProperty };
