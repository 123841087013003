import { alpha, createTheme } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import { useGetCurrentMode } from './app/hooks';

declare module '@mui/material/styles' {
  interface Palette {
    qualityNormal?: Palette['primary'];
    qualitySuperior?: Palette['primary'];
    qualityFlawless?: Palette['primary'];
    qualityEpic?: Palette['primary'];
    qualityLegendary?: Palette['primary'];
  }
  interface PaletteOptions {
    qualityNormal?: PaletteOptions['primary'];
    qualitySuperior?: PaletteOptions['primary'];
    qualityFlawless?: PaletteOptions['primary'];
    qualityEpic?: PaletteOptions['primary'];
    qualityLegendary?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    qualityNormal: true;
    qualitySuperior: true;
    qualityFlawless: true;
    qualityEpic: true;
    qualityLegendary: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    qualityNormal: true;
    qualitySuperior: true;
    qualityFlawless: true;
    qualityEpic: true;
    qualityLegendary: true;
  }
}

const hoverBackgroundColorAlpha = 0.18;

const useGetTheme = () => {
  const mode = useGetCurrentMode();

  const theme = createTheme({
    components: {
      MuiCheckbox: {
        variants: [
          {
            props: { color: 'qualityNormal' },
            style: {
              color: '#b6b6b6',
              '&:hover': {
                backgroundColor: alpha('#b6b6b6', hoverBackgroundColorAlpha),
              },
            },
          },
          {
            props: { color: 'qualitySuperior' },
            style: {
              color: '#6ce86c',
              '&:hover': {
                backgroundColor: alpha('#6ce86c', hoverBackgroundColorAlpha),
              },
            },
          },
          {
            props: { color: 'qualityFlawless' },
            style: {
              color: '#00dfff',
              '&:hover': {
                backgroundColor: alpha('#00dfff', hoverBackgroundColorAlpha),
              },
            },
          },
          {
            props: { color: 'qualityEpic' },
            style: {
              color: '#ed95f9',
              '&:hover': {
                backgroundColor: alpha('#ed95f9', hoverBackgroundColorAlpha),
              },
            },
          },
          {
            props: { color: 'qualityLegendary' },
            style: {
              color: '#ffd936',
              '&:hover': {
                backgroundColor: alpha('#ffd936', hoverBackgroundColorAlpha),
              },
            },
          },
        ],
      },
    },
    palette: {
      mode,
      qualityNormal: {
        //   main: '#878787',
        main: '#b6b6b6',
      },
      qualitySuperior: {
        //   main: '#04ff80',
        main: '#6ce86c',
      },
      qualityFlawless: {
        main: '#00dfff',
      },
      qualityEpic: {
        //   main: '#bc00fb',
        main: '#ed95f9',
      },
      qualityLegendary: {
        //   main: '#ffe37e',
        main: '#ffd936',
      },
    },
  });
  return theme;
};

export default useGetTheme;
