import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormApi } from 'final-form';
import { isEqual } from 'lodash';
import { Form } from 'react-final-form';
import {
  useAppDispatch,
  useAppSelector,
  useBuildDefaultTypeFiltersSettings,
} from '../../app/hooks';
import { useCloseKingbaitSettingsModal } from '../core/hooks';
import SettingsForm from '../core/SettingsForm';
import { saveKingbaitSettingsAsync } from '../user/userSlice';

const KingBaitSettingsModal = () => {
  const dispatch = useAppDispatch();
  const closeModal = useCloseKingbaitSettingsModal();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultValues = useBuildDefaultTypeFiltersSettings();
  const initialValues = useAppSelector(
    (state) => state.user.kingBait?.settings ?? defaultValues
  );

  const open = useAppSelector((state) => state.core.kingBaitSettingsModal.open);
  const handleSubmit = (values: typeof initialValues) => {
    closeModal();
    dispatch(saveKingbaitSettingsAsync(values));
  };
  const handleSetDefault = (form: FormApi<typeof initialValues>) => {
    if (isEqual(initialValues, defaultValues)) {
      form.reset();
    } else {
      form.change('shareSettings', defaultValues.shareSettings);
      form.change('type', defaultValues.type);
    }
  };

  const handleDialogClose = () => {
    closeModal();
  };
  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={handleDialogClose}
            scroll="paper"
            disablePortal={true}
            fullScreen={matches}
            PaperProps={{
              sx: { minWidth: '450px' },
            }}
          >
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
              <SettingsForm name="type" />
            </DialogContent>
            <DialogActions>
              <Button color="warning" onClick={handleDialogClose}>
                Cancel
              </Button>
              <Button color="warning" onClick={() => handleSetDefault(form)}>
                Default
              </Button>
              <Button
                onClick={form.reset as any}
                disabled={submitting || pristine}
              >
                Reset
              </Button>
              <Button type="submit" disabled={submitting || pristine}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

export default KingBaitSettingsModal;
