import { Switch } from '@mui/material';
import { useGetUserWorker } from '../../app/hooks';
import { IWorker } from '../data/dataSlice';
import { useToggleUnlocked } from './hooks';

interface IWorkerTableCellUnlockedProps {
  worker: IWorker;
  disabled?: boolean;
}

const WorkerTableCellUnlocked: React.FC<IWorkerTableCellUnlockedProps> = ({
  worker,
  disabled = false,
}) => {
  const userWorker = useGetUserWorker(worker.id);
  const handleToggleUnlocked = useToggleUnlocked(worker.id);
  return (
    <Switch
      disabled={disabled}
      checked={userWorker?.unlocked}
      color="success"
      onChange={handleToggleUnlocked}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

export default WorkerTableCellUnlocked;
