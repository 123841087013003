import { EntityId } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { blueprintSelectors } from '../../features/data/dataSlice';

import { useAppSelector } from './';

const useGetBlueprint = (id: EntityId) => {
  const blueprint = useAppSelector(
    (state) => (id ? blueprintSelectors.selectById(state, id) : undefined),
    isEqual
  );
  return blueprint;
};

export { useGetBlueprint };
