import { createSelector } from '@reduxjs/toolkit';
import { capitalize, isEqual } from 'lodash';
import {
  useAppSelector,
  useBuildDefaultTypeFilterSettings,
  useGetBlueprintsWithCollection,
} from '.';
import { airshipCategories } from '../../features/data/dataHelpers';
import {
  Blueprint,
  BlueprintWithCollection,
} from '../../features/data/dataSlice';
import {
  ISettingsAirshipCategories,
  ISettingsTypeFilter,
  ISettingsFilterTypes,
} from '../../features/user/userSlice';
import { RootState } from '../store';

interface IUseGetBlueprintsWCFromSettingsFn {
  (
    feature: 'kingBait',
    bpType: ISettingsFilterTypes,
    category?: never
  ): BlueprintWithCollection[];
  (
    feature: 'airship',
    bpType: Exclude<ISettingsFilterTypes, 'item'>,
    category?: never
  ): BlueprintWithCollection[];
  (
    feature: 'airship',
    bpType: 'item',
    category: ISettingsAirshipCategories
  ): BlueprintWithCollection[];
}

interface IGetFn<T> {
  (
    state: RootState,
    feature: 'kingBait' | 'airship',
    bpType: ISettingsFilterTypes,
    category?: ISettingsAirshipCategories
  ): T;
}

const useGetSettings = () => {
  const defaultSettings = useBuildDefaultTypeFilterSettings();
  const getSettings: IGetFn<ISettingsTypeFilter> = (
    state,
    feature,
    bpType,
    category
  ) => {
    let settings: ISettingsTypeFilter | undefined;
    switch (feature) {
      case 'airship':
        const airship = state.user.airship;
        const airshipCategory = airship?.shareSettings ? 'all' : category;
        if (airshipCategory) {
          const airshipType = airship?.settings[airshipCategory].shareSettings
            ? 'all'
            : bpType;

          settings = airship?.settings[airshipCategory].type[airshipType];
        }
        break;
      case 'kingBait':
        const kingBait = state.user.kingBait?.settings;
        if (kingBait) {
          const kingBaitType = kingBait.shareSettings ? 'all' : bpType;
          settings = kingBait.type[kingBaitType];
        }
        break;
    }

    return settings ?? defaultSettings;
  };
  return getSettings;
};

interface IUseGetBlueprintsFn {
  (
    feature: 'kingBait' | 'airship',
    bpType: ISettingsFilterTypes,
    category?: ISettingsAirshipCategories
  ): IGetFn<BlueprintWithCollection[]>;
}
const useGetBlueprints: IUseGetBlueprintsFn = (feature, bpType, category) => {
  let bpFilter: (bp: Blueprint) => boolean = () => true;
  if (bpType === 'item') {
    switch (feature) {
      case 'airship':
        const airshipCategory = airshipCategories.find(
          (ac) => ac.key === category
        );
        if (airshipCategory) {
          bpFilter = (bp) => bp.airshipCategory === airshipCategory.name;
        } else {
          bpFilter = () => false;
        }
        break;
      case 'kingBait':
        bpFilter = (bp) =>
          !['Element', 'Spirit', 'Runestone', 'Moonstone'].includes(bp.type);
        break;
    }
  } else {
    bpFilter = (bp) => bp.type === capitalize(bpType);
  }
  const blueprints = useGetBlueprintsWithCollection(bpFilter);

  const getBlueprints: IGetFn<BlueprintWithCollection[]> = (state) => {
    return blueprints;
  };
  return getBlueprints;
};

const filterBlueprints = (
  blueprints: BlueprintWithCollection[],
  settings: ISettingsTypeFilter,
  sort: boolean
) => {
  const filteredBlueprints = blueprints.filter((bp) => {
    const learned =
      bp.learned &&
      bp.tier >= settings.learnedTier[0] &&
      bp.tier <= settings.learnedTier[1];

    const notLearned =
      settings.includeNotlearned &&
      !bp.learned &&
      bp.tier >= settings.notlearnedTier[0] &&
      bp.tier <= settings.notlearnedTier[1];

    return learned || notLearned;
  });
  if (!sort) {
    return filteredBlueprints;
  }

  return filteredBlueprints.sort((a, b) => {
    if (a.tier === b.tier) {
      if (a.learned && !b.learned) {
        return -1;
      } else if (!a.learned && b.learned) {
        return 1;
      } else {
        return 0;
      }
    }
    return a.tier - b.tier;
  });
};

const useGetBlueprintsWCFromSettings: IUseGetBlueprintsWCFromSettingsFn = (
  feature,
  bpType,
  category
) => {
  const getBlueprints = useGetBlueprints(feature, bpType, category);
  const getSettings = useGetSettings();
  return useAppSelector(
    createSelector(
      (state: RootState) => getBlueprints(state, feature, bpType, category),
      (state: RootState) => getSettings(state, feature, bpType, category),
      (blueprints, settings) => filterBlueprints(blueprints, settings, false)
    ),
    isEqual
  );
};

export { useGetBlueprintsWCFromSettings };
