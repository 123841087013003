import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useGetAsset } from '../../app/hooks';
import { Asset } from '../data/dataSlice';
import AssetBoxOverlayContainer from './AssetBoxOverlayContainer';

const dropShadowColors = {
  affinity: { color: '0, 238, 255', size: 1, multiplier: 2.5 },
  builtin: { color: '182, 182, 182', size: 1, multiplier: 2.5 },
  normal: null,
  superior: { color: '45, 205, 0', size: 1, multiplier: 5 },
  flawless: { color: '0, 238, 255', size: 1, multiplier: 5 },
  epic: { color: '195, 0, 255', size: 1, multiplier: 5 },
  legendary: { color: '255, 230, 0', size: 1, multiplier: 5 },
};

export type IDropShadowColorTypes = keyof typeof dropShadowColors;

interface BaseAssetProps {
  sx?: SxProps<Theme>;
  width?: string;
  height?: string;
  // dropShadow?: boolean;
  // dropShadowColor?: string;
  // dropShadowSize?: number;
  // affinityShadow?: boolean;
  // affinityShadowSize?: number;
  // builtInShadow?: boolean;
  // builtInShadowSize?: number;
  filter?: string;
  handleError?: boolean;
  shadow?: IDropShadowColorTypes | boolean;
  shadowSize?: number;
  shadowMultiplier?: number;
}

type ConditionalAssetProps =
  | {
      search: string;
      type?: Asset['type'];
      src?: never;
    }
  | {
      type?: never;
      search?: never;
      src: string | undefined;
    };

type FullAssetProps = BaseAssetProps & ConditionalAssetProps;

export type AssetBoxProps = FullAssetProps & {
  overlay?: FullAssetProps;
};

interface GetDropShadowSxFn {
  (
    color: BaseAssetProps['shadow'],
    size?: BaseAssetProps['shadowSize'],
    multiplier?: BaseAssetProps['shadowMultiplier']
  ): SxProps<Theme>;
}
const getDropShadowSx: GetDropShadowSxFn = (color, size, multiplier) => {
  switch (color) {
    case true:
      return { filter: 'drop-shadow(5px 5px 5px #888888)' } as SxProps<Theme>;
    case false:
    case undefined:
    case 'normal':
      return {} as SxProps<Theme>;
    default:
      const c = dropShadowColors[color].color;
      const s: number = size ?? dropShadowColors[color].size;
      const m: number = multiplier ?? dropShadowColors[color].multiplier;
      return {
        filter:
          `drop-shadow(-${s}px -${s}px 0 rgb(${c})) ` +
          `drop-shadow(${s}px ${s}px 0 rgb(${c})) ` +
          `drop-shadow(${s}px -${s}px 0 rgb(${c})) ` +
          `drop-shadow(-${s}px ${s}px 0 rgb(${c})) ` +
          `drop-shadow(0 0 ${s * m}px rgba(${c}, 0.5)) ` +
          `drop-shadow(0 0 ${s * m}px rgba(${c}, 0.5))`,
      } as SxProps<Theme>;
  }
};

const AssetBox: React.FC<AssetBoxProps> = ({
  src,
  search,
  type,
  sx,
  width = '40px',
  height = '40px',
  overlay,
  shadow,
  shadowSize,
  shadowMultiplier,
  handleError = true,
  // affinityShadow = false,
  // affinityShadowSize = 1,
  // builtInShadow = false,
  // builtInShadowSize = 1,
  // dropShadow = false,
}) => {
  const imgSrc = useGetAsset(search, type) || src;
  const overlaySrc =
    useGetAsset(overlay?.search, overlay?.type) || overlay?.src;
  if (!imgSrc) return null;
  const imgSrcWithAssets = imgSrc.startsWith('/assets')
    ? imgSrc
    : '/assets' + imgSrc;

  const newSx = {
    // ...(dropShadow ? dropShadowSx : {}),
    // ...(affinityShadow
    //   ? getDropShadowSx('affinity', affinityShadowSize)
    //   : // ? getDropShadowSx('0, 238, 255', affinityShadowSize)
    //     {}),
    // ...(builtInShadow
    //   ? getDropShadowSx('builtin', builtInShadowSize)
    //   : // ? getDropShadowSx('182, 182, 182', builtInShadowSize)
    //     {}),
    ...(shadow ? getDropShadowSx(shadow, shadowSize, shadowMultiplier) : {}),
    ...sx,
  } as SxProps<Theme>;

  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgTag = e.target as HTMLImageElement;
    imgTag.src = '/assets/Logos/ShopTitans_Logo_RGB.png';
  };

  return (
    <AssetBoxOverlayContainer
      renderOverlay={!!overlay}
      width={width}
      height={height}
    >
      <Box
        width={width}
        height={height}
        component="img"
        src={imgSrcWithAssets.replace(' ', '%20')}
        alt=""
        sx={newSx}
        onError={handleError ? onError : undefined}
      />
      {overlay && (
        <Box
          width={width}
          height={height}
          position="absolute"
          top="0"
          left="0"
          display="flex"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width={overlay.width || width}
            height={overlay.height || height}
            component="img"
            src={overlaySrc}
            alt=""
            onError={overlay.handleError ? onError : undefined}
            sx={
              {
                ...sx,
                ...getDropShadowSx(
                  overlay.shadow,
                  overlay.shadowSize,
                  overlay.shadowMultiplier
                ),
                ...overlay.sx,
              } as SxProps<Theme>
            }
          />
        </Box>
      )}
    </AssetBoxOverlayContainer>
  );
};

export default AssetBox;
