import { EntityId } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../app/hooks';
import { ISettingsAirshipCategories } from '../user/userSlice';
import {
  closeAirshipSettingsModal,
  closeBlueprintModal,
  closeKingBaitSettingsModal,
  closeWorkerModal,
  CoreInitialState,
  openAirshipSettingsModal,
  openBlueprintModal,
  OpenBlueprintModalPayload,
  openKingBaitSettingsModal,
  openWorkerModal,
  setBlueprintModalEntity,
  setBlueprintModalQuality,
  setBlueprintModalTab,
} from './coreSlice';

export const useOpenAirshipSettingsModal = () => {
  const dispatch = useAppDispatch();
  return (tab?: ISettingsAirshipCategories) =>
    dispatch(openAirshipSettingsModal(tab));
};
export const useCloseAirshipSettingsModal = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(closeAirshipSettingsModal());
};

export const useOpenBlueprintModal = () => {
  const dispatch = useAppDispatch();
  return (
    blueprintId: OpenBlueprintModalPayload['blueprintId'],
    tab?: OpenBlueprintModalPayload['tab'],
    elementId?: OpenBlueprintModalPayload['elementId'],
    spiritId?: OpenBlueprintModalPayload['spiritId'],
    quality?: OpenBlueprintModalPayload['quality']
  ) =>
    dispatch(
      openBlueprintModal({ blueprintId, tab, elementId, spiritId, quality })
    );
};

export const useCloseBlueprintModal = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(closeBlueprintModal());
};

export const useSetBlueprintModalQuality = () => {
  const dispatch = useAppDispatch();
  return (quality: CoreInitialState['blueprintModal']['quality']) =>
    dispatch(setBlueprintModalQuality(quality));
};

export const useSetBlueprintModalTab = () => {
  const dispatch = useAppDispatch();
  return (tab: CoreInitialState['blueprintModal']['tab']) =>
    dispatch(setBlueprintModalTab(tab));
};

export const useSetBlueprintModalEntity = () => {
  const dispatch = useAppDispatch();
  return (
    type: 'blueprint' | 'element' | 'spirit',
    entityId: EntityId | undefined
  ) => dispatch(setBlueprintModalEntity({ type, entityId }));
};

export const useOpenKingbaitSettingsModal = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(openKingBaitSettingsModal());
};

export const useCloseKingbaitSettingsModal = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(closeKingBaitSettingsModal());
};

export const useOpenWorkerModal = () => {
  const dispatch = useAppDispatch();
  return (workerId: EntityId) => dispatch(openWorkerModal({ workerId }));
};

export const useCloseWorkerModal = () => {
  const dispatch = useAppDispatch();
  return () => dispatch(closeWorkerModal());
};

/*
openBlueprintModal,
closeBlueprintModal,
setBlueprintModalEntity,
setBlueprintModalQuality,
// */
