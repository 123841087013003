import React from 'react';
import { Switch } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';

import { useGetCollectionProp } from '../../app/hooks';
import { useHandleToggleProp } from './hooks';

type Props = {
  id: EntityId;
};

const CollectionTableCellLearned: React.FC<Props> = ({ id }) => {
  const learned = useGetCollectionProp(id, 'learned') || false;
  const handleToggleLearned = useHandleToggleProp(id, 'learned');

  return (
    <Switch
      checked={learned}
      color="success"
      onChange={handleToggleLearned}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

export default CollectionTableCellLearned;

export const MemoizedCollectionTableCellLearned = React.memo(
  CollectionTableCellLearned
);
