import { Box, Button } from '@mui/material';
import { uniq } from 'lodash';
import { useAppSelector, useKabamFetchData } from '../../app/hooks';
import { blueprintSelectors } from '../data/dataSlice';
import AssetBox from './AssetBox';

const ListAllAssets = () => {
  const kabamFetchData = useKabamFetchData();
  const loading = useAppSelector((state) => state.data.status === 'loading');
  const allAssets = useAppSelector((state) => state.data.assets);
  const assetTypes = uniq(allAssets.map((asset) => asset.type));
  const blueprints = useAppSelector((state) =>
    blueprintSelectors.selectAll(state)
  );

  return (
    <Box>
      <Button
        onClick={() => kabamFetchData()}
        disabled={loading}
        variant="contained"
      >
        Reload data
      </Button>
      <Box sx={{}}>
        {blueprints.map((bp) => {
          return (
            <Box key={bp.id}>
              {bp.name}
              <Box
                component="img"
                src={bp.asset}
                sx={{ width: '20px', height: '20px' }}
                onError={(e) => {
                  const imgTag = e.target as HTMLImageElement;
                  imgTag.style.display = 'none';
                }}
                onLoad={(e) => {
                  const target = e.target as HTMLImageElement;
                  const parent = target.parentNode as HTMLDivElement;
                  parent.style.display = 'none';
                }}
              />
            </Box>
          );
        })}
      </Box>
      {assetTypes.map((type) => {
        const assets = allAssets.filter((asset) => asset.type === type);
        return (
          <Box key={type}>
            <Box component="h3">{type}</Box>
            <Box display="flex" gap={1} flexWrap="wrap">
              {assets.map((asset, index) => (
                <Box
                  key={index + '_' + asset.name}
                  sx={{ border: '5px solid black', borderRadius: 5, p: 1 }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box component="h4" mt={0} mb={0}>
                    {asset.name}
                  </Box>
                  <AssetBox width="150px" height="150px" src={asset.asset} />
                </Box>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ListAllAssets;
