import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
  renderOverlay?: boolean;
  width: string;
  height: string;
}

const AssetBoxOverlayContainer: React.FC<Props> = ({
  children,
  width,
  height,
  renderOverlay = false,
}) => {
  if (!renderOverlay) return <>{children}</>;
  return (
    <Box position="relative" sx={{ width, height, overflow: 'visible' }}>
      {children}
    </Box>
  );
};

export default AssetBoxOverlayContainer;
