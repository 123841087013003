import { Box, Checkbox, IconButton, SxProps, Theme } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AssetBox from '../asset/AssetBox';
import { useAppSelector } from '../../app/hooks';
import { blueprintQualities } from '../collection/CollectionTableCollected';
import { useOpenKingbaitSettingsModal } from '../core/hooks';
import { useSaveKingBaitQuality } from './hooks';

const initialSx: SxProps<Theme> = {
  width: '24px',
  height: '24px',
};

const size = {
  width: '40px',
  height: '40px',
};

const KingBaitTopToolbar = () => {
  const quality = useAppSelector((s) => s.user.kingBait?.quality || 'superior');
  const openSettingsModal = useOpenKingbaitSettingsModal();
  const setQuality = useSaveKingBaitQuality();
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box
        component="h3"
        mb={0}
        mt={0}
        display="flex"
        gap={1}
        alignItems="center"
      >
        <AssetBox
          width="24px"
          height="24px"
          type="characterPortraits"
          search="Reinhold"
        />
        <Box>King Bait</Box>
      </Box>
      <Box display="flex" gap={1}>
        {blueprintQualities.map((q) => {
          if (q.name === 'normal') return null;
          return (
            <Checkbox
              key={q.name}
              checked={quality === q.name}
              color={q.color}
              onChange={() => (q.name === 'normal' ? null : setQuality(q.name))}
              checkedIcon={
                <AssetBox {...size} src={q.asset} sx={initialSx} shadow />
              }
              icon={
                <AssetBox
                  width="24px"
                  height="24px"
                  src={q.asset}
                  sx={{
                    ...initialSx,
                    ...{ opacity: 0.35, grayscale: 0.55 },
                  }}
                  shadow
                />
              }
            />
          );
        })}
      </Box>
      <IconButton onClick={openSettingsModal}>
        <SettingsIcon />
      </IconButton>
    </Box>
  );
};

export default KingBaitTopToolbar;
