import { EntityId } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { Collection, collectionSelectors } from '../../features/user/userSlice';
import { useAppSelector } from '.';

interface UseGetCollectionPropsFn {
  <T extends keyof Collection>(input: EntityId, prop: T): Collection[T] | null;
}

const useGetCollectionProp: UseGetCollectionPropsFn = (id, prop) => {
  const propData = useAppSelector(
    (state) =>
      id ? collectionSelectors.selectById(state, id)?.[prop] : undefined,
    isEqual
  );
  return propData || null;
};

export { useGetCollectionProp };
