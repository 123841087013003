import { Box, Stack, SxProps, Theme, Tooltip } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import AssetBox from '../asset/AssetBox';
import { Asset } from '../data/dataSlice';
import { blueprintSelector } from './BlueprintModal';

interface IResourceType {
  key:
    | 'iron'
    | 'wood'
    | 'leather'
    | 'herbs'
    | 'steel'
    | 'ironwood'
    | 'fabric'
    | 'oils'
    | 'mana'
    | 'gems'
    | 'essence';
  name: string;
  type: Asset['type'];
}
const resources: IResourceType[] = [
  { key: 'iron', name: 'Iron', type: 'resource' },
  { key: 'wood', name: 'Wood', type: 'resource' },
  { key: 'leather', name: 'Leather', type: 'resource' },
  { key: 'herbs', name: 'Herbs', type: 'resource' },
  { key: 'steel', name: 'Steel', type: 'resource' },
  { key: 'ironwood', name: 'Ironwood', type: 'resource' },
  { key: 'fabric', name: 'Fabric', type: 'resource' },
  { key: 'oils', name: 'Oil', type: 'resource' },
  { key: 'mana', name: 'Ether', type: 'resource' },
  { key: 'gems', name: 'Jewels', type: 'resource' },
  { key: 'essence', name: 'Essence', type: 'resource' },
];

interface ISx {
  Normal: SxProps<Theme>;
  Superior: SxProps<Theme>;
  Flawless: SxProps<Theme>;
  Epic: SxProps<Theme>;
  Legendary: SxProps<Theme>;
}

const sx: ISx = {
  Normal: {},
  Superior: {
    filter: `drop-shadow(-1px -1px 0 rgb(45, 205, 0))
    drop-shadow(1px 1px 0 rgb(45, 205, 0))
    drop-shadow(1px -1px 0 rgb(45, 205, 0))
    drop-shadow(-1px 1px 0 rgb(45, 205, 0))
    drop-shadow(0 0 5px rgba(45, 205, 0, 0.5))
    drop-shadow(0 0 5px rgba(45, 205, 0, 0.5))
    `,
  },
  Flawless: {
    filter: `drop-shadow(-1px -1px 0 rgb(0, 238, 255))
    drop-shadow(1px 1px 0 rgb(0, 238, 255))
    drop-shadow(1px -1px 0 rgb(0, 238, 255))
    drop-shadow(-1px 1px 0 rgb(0, 238, 255))
    drop-shadow(0 0 5px rgba(0, 238, 255, 0.5))
    drop-shadow(0 0 5px rgba(0, 238, 255, 0.5))
    `,
  },
  Epic: {
    filter: `drop-shadow(-1px -1px 0 rgb(195, 0, 255))
    drop-shadow(1px 1px 0 rgb(195, 0, 255))
    drop-shadow(1px -1px 0 rgb(195, 0, 255))
    drop-shadow(-1px 1px 0 rgb(195, 0, 255))
    drop-shadow(0 0 5px rgba(195, 0, 255, 0.5))
    drop-shadow(0 0 5px rgba(195, 0, 255, 0.5))
    `,
  },
  Legendary: {
    filter: `drop-shadow(-1px -1px 0 rgb(255, 230, 0))
    drop-shadow(1px 1px 0 rgb(255, 230, 0))
    drop-shadow(1px -1px 0 rgb(255, 230, 0))
    drop-shadow(-1px 1px 0 rgb(255, 230, 0))
    drop-shadow(0 0 5px rgba(255, 230, 0, 0.5))
    drop-shadow(0 0 5px rgba(255, 230, 0, 0.5))
    `,
  },
};

const BlueprintModalBlueprintResources = () => {
  const bp = useAppSelector((s) => blueprintSelector(s, 'blueprint'));
  const components = useAppSelector((s) =>
    s.data.assets.filter((a) => a.type === 'component')
  );
  if (!bp) return null;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box sx={{ textAlign: 'center' }}>Resources</Box>
      <Stack direction="row" spacing={3} justifyContent="center">
        {resources.map((r) => {
          if (!bp[r.key]) return null;
          return (
            <Tooltip arrow title={r.name} placement="top" key={r.key}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <AssetBox type={r.type} search={r.name} />
                <Box>{bp[r.key]}</Box>
              </Box>
            </Tooltip>
          );
        })}
        {[1, 2].map((i) => {
          const rssProp = `component${i}` as 'component1' | 'component2';
          const name = bp[rssProp];
          if (!name) return null;
          const amountProp = `amountNeeded${i}` as
            | 'amountNeeded1'
            | 'amountNeeded2';
          const qualityProp = `componentQuality${i}` as
            | 'componentQuality1'
            | 'componentQuality2';
          const quality = bp[qualityProp] as 'Normal' | 'Superior' | undefined;
          const tooltipName = `${quality ? quality + ' ' : ''}${name}`;
          return (
            <Tooltip arrow title={tooltipName} placement="top" key={i}>
              <Box display="flex" flexDirection="column" alignItems="center">
                {!!components.find((c) => c.name === name) ? (
                  <AssetBox type="component" search={name} />
                ) : (
                  <AssetBox
                    type="blueprint"
                    search={name}
                    sx={quality ? sx[quality] : {}}
                  />
                )}
                <Box>{bp[amountProp]}</Box>
              </Box>
            </Tooltip>
          );
        })}
      </Stack>
    </Box>
  );
};

export default BlueprintModalBlueprintResources;
