import { useAppDispatch } from '../../app/hooks';
import {
  fetchData,
  IImportData,
  importDataAsync,
} from '../../features/data/dataSlice';

export const useKabamFetchData = () => {
  const dispatch = useAppDispatch();

  return async () => {
    try {
      await dispatch(fetchData());
    } catch (err) {
      console.log(err);
    }
  };
};

export const useImportData = () => {
  const dispatch = useAppDispatch();

  return async (data: IImportData) => {
    try {
      await dispatch(importDataAsync(data));
    } catch (err) {
      console.log(err);
    }
  };
};
