import { Asset } from '../../features/data/dataSlice';
import { useAppSelector } from './storeHooks';

const typeMap: {
  [key: string]: Asset['type'];
} = {
  spiritEnchanted: 'spiritAffinity',
};

export const useGetAsset = (
  search: string | undefined,
  type?: Asset['type']
) => {
  const asset = useAppSelector((state) =>
    state.data.assets.find(
      (asset) =>
        (type ? asset.type === (typeMap[type] ?? type) : true) &&
        asset.name === search
    )
  );

  if (!asset) return undefined;
  return asset.asset.startsWith('/assets')
    ? asset.asset
    : '/assets' + asset.asset;
};
