import { MRT_ColumnDef } from 'material-react-table';

interface IMRTFixedCellSizeFn {
  <T extends Record<string, any> = {}>(
    body?: MRT_ColumnDef<T>['muiTableBodyCellProps'],
    head?: MRT_ColumnDef<T>['muiTableHeadCellProps']
  ): {
    muiTableBodyCellProps: MRT_ColumnDef<T>['muiTableBodyCellProps'];
    muiTableHeadCellProps: MRT_ColumnDef<T>['muiTableHeadCellProps'];
  };
}

export const MRTFixedCellSize: IMRTFixedCellSizeFn = (
  body = {},
  head = {}
) => ({
  muiTableBodyCellProps: {
    sx: { flex: '0 0 auto' },
    ...body,
  },
  muiTableHeadCellProps: {
    sx: { flex: '0 0 auto' },
    ...head,
  },
});
