import React from 'react';
import {
  Box,
  Modal,
  ModalProps,
  Paper,
  SxProps,
  Tab,
  Tabs,
  Theme,
} from '@mui/material';

import { useAppSelector } from '../../app/hooks';
import BlueprintImage from './BlueprintImage';
import BlueprintModalAirshipPower from './BlueprintModalAirshipPower';
import { RootState } from '../../app/store';
import { blueprintSelectors, BlueprintWithCollection } from '../data/dataSlice';
import { useCloseBlueprintModal, useSetBlueprintModalTab } from '../core/hooks';
import Banner from './Banner';
import { TabContext, TabPanel } from '@mui/lab/';
import BlueprintModalWorkers from './BlueprintModalWorkers';
import BlueprintModalCollection from './BlueprintModalCollection';
import BlueprintModalBlueprint from './BlueprintModalBlueprint';
import { CoreInitialState } from '../core/coreSlice';
import { collectionSelectors, getNewCollection } from '../user/userSlice';

const style: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  minWidth: '460px',
  outline: 'none',
  pointerEvents: 'none',
};

const paperStyle: SxProps<Theme> = {
  border: '2px solid #000',
  boxShadow: 24,
  pointerEvents: 'auto',
};

export const blueprintSelector = (
  state: RootState,
  type: 'blueprint' | 'element' | 'spirit'
) => {
  const entityId = state.core.blueprintModal[`${type}Id`];
  if (entityId) {
    const collection = collectionSelectors.selectById(state, entityId);
    const blueprint = blueprintSelectors.selectById(state, entityId);
    return {
      ...blueprint,
      ...(collection ?? getNewCollection(entityId)),
    } as BlueprintWithCollection;
  }
};

const BlueprintModal = () => {
  const open = useAppSelector((s) => s.core.blueprintModal.open);
  const bp = useAppSelector((s) => blueprintSelector(s, 'blueprint'));
  const currTab = useAppSelector((s) => s.core.blueprintModal.tab);
  // const element = useAppSelector((s) => blueprintSelector(s, 'element'));
  // const spirit = useAppSelector((s) => blueprintSelector(s, 'spirit'));
  const closeModal = useCloseBlueprintModal();
  const setCurrTab = useSetBlueprintModalTab();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CoreInitialState['blueprintModal']['tab']
  ) => {
    setCurrTab(newValue);
  };
  const onClose: ModalProps['onClose'] = (event, reason) => {
    // setCurrTab(defaultTab);
    closeModal();
  };

  if (!bp) return null;

  const showAirship = !!bp.airshipCategory || bp.type === 'Familiar';
  const showTab =
    currTab === 'airship' && !showAirship ? 'collection' : currTab;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <BlueprintImage id={bp.id} />
        <Paper sx={paperStyle}>
          <Box>
            <Box display="flex" justifyContent="center">
              <Banner containerSx={{ mt: -3 }}>{bp.name}</Banner>
            </Box>
            <TabContext value={showTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={showTab}
                  onChange={handleChange}
                  centered
                  aria-label="basic tabs example"
                >
                  <Tab label="Blueprint" value="blueprint" />
                  <Tab
                    value="airship"
                    label="Calculate"
                    sx={{ display: showAirship ? '' : 'none' }}
                  />
                  <Tab label="Collection" value="collection" />
                </Tabs>
              </Box>
              <TabPanel value="blueprint">
                <BlueprintModalBlueprint />
              </TabPanel>
              <TabPanel value="airship">
                <BlueprintModalAirshipPower />
              </TabPanel>
              <TabPanel value="collection">
                <BlueprintModalCollection />
              </TabPanel>
              <TabPanel value="workers">
                <BlueprintModalWorkers />
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default BlueprintModal;
