import { Box } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import React from 'react';
import { useGetBlueprint } from '../../app/hooks';
import AssetBox from '../asset/AssetBox';

type Props = {
  id: EntityId;
};

const BlueprintImage: React.FC<Props> = ({ id }) => {
  const blueprint = useGetBlueprint(id);
  if (!blueprint) return null;

  const asset = () => {
    if (blueprint.availableFromChest) {
      if (
        blueprint.availableFromChest === 'Opulent Chest' ||
        blueprint.availableFromChest === 'Luxurious Chest'
      ) {
        return '/assets/background_model_lcog.png';
      }
      return '/assets/background_model_special.png';
    }
    if (blueprint.unlockPrerequisite?.endsWith('Pack')) {
      return '/assets/background_model_premium.png';
    }
    return '/assets/background_model_standard.png';
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{
        mb: 1,
        filter: 'drop-shadow(0px 0px 2px #000)',
        pointerEvents: 'none',
      }}
    >
      <AssetBox
        src={asset()}
        width="250px"
        height="267px"
        sx={{ pointerEvents: 'auto' }}
        overlay={{
          src: blueprint.asset,
          width: '180px',
          height: '180px',
          shadow: true,
          handleError: true,
          sx: { mt: '-9px' },
        }}
      />
    </Box>
  );
};

export default BlueprintImage;
