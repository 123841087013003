import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from '../App';
import CalculateAll from '../features/airship/CalculateAll';
import ListAllAssets from '../features/asset/ListAllAssets';
import BlueprintsTable from '../features/blueprint/BlueprintsTable';
import DataAdmin from '../features/data/DataAdmin';
import KingBait from '../features/kingBait/KingBait';
import KingsCaprice from '../features/kingsCaprice/KingsCaprice';
import Merchant from '../features/merchant/Merchant';
import PreCraft from '../features/precraft/PreCraft';
import Version from '../features/version/Version';
import ErrorPage from './ErrorPage';

const VirtualizedTable = () => {
  return <BlueprintsTable virtualization={true} />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <CalculateAll />,
      },
      {
        path: '/kingbait',
        element: <KingBait />,
      },
      {
        path: '/kingscaprice',
        element: <KingsCaprice />,
      },
      {
        path: '/collection',
        element: <BlueprintsTable />,
      },
      {
        path: '/precraft',
        element: <PreCraft />,
      },
      {
        path: '/virtualized',
        element: <VirtualizedTable />,
      },
      {
        path: '/merchant',
        element: <Merchant />,
      },
      {
        path: '/assets',
        element: <ListAllAssets />,
      },
      {
        path: '/changelog',
        element: <Version />,
      },
      {
        path: '/data',
        element: <DataAdmin />,
      },
    ],
  },
]);

const RootRoute = () => {
  return <RouterProvider router={router} />;
};

export default RootRoute;
