import { EntityId } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { Blueprint, blueprintSelectors } from '../../features/data/dataSlice';
import { useAppSelector } from '.';

type Props = keyof Blueprint;

const useGetBlueprintProp = <T extends Props>(
  id: EntityId | undefined,
  prop: T
) => {
  const propData = useAppSelector(
    (state) =>
      id ? blueprintSelectors.selectById(state, id)?.[prop] : undefined,
    isEqual
  );
  return propData || null;
};

export { useGetBlueprintProp };
