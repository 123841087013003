import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { EntityId } from '@reduxjs/toolkit';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ColumnDef,
} from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCalculateBlueprintProperty,
  useGetBlueprintsInAirshipCategory,
  useGetEnchantmentsForAirship,
} from '../../app/hooks';
import { Blueprint, BlueprintWithCollection } from '../data/dataSlice';
import { AirshipCategory, airshipCategories } from '../data/dataHelpers';
import React from 'react';
import CalculateCategoryTopToolbar from './CalculateCategoryTopToolbar';
import { useOpenBlueprintModal } from '../core/hooks';
import CalculateCategoryItemIcon from './CalculateCategoryItemIcon';
import CalculateCategoryEnchants from './CalculateCategoryEnchants';
import { MRTFixedCellSize } from '../core/coreHelpers';
import { useGetAirshipCategoryPageSize } from '../../app/hooks';

type Props = {
  category: AirshipCategory['key'];
};

export type AirshipPower = {
  id: EntityId;
  name: Blueprint['name'];
  blueprint: BlueprintWithCollection;
  element: BlueprintWithCollection | undefined;
  spirit: BlueprintWithCollection | undefined;
  power: number;
};

const CalculateCategory: React.FC<Props> = ({ category }) => {
  const airshipCategory = airshipCategories.find((c) => c.key === category);
  const openModal = useOpenBlueprintModal();
  const [timeUsed, setTimeUsed] = useState(0);
  const [count, setCount] = useState(0);
  const [airshipPowers, setAirshipPowers] = useState<AirshipPower[]>([]);
  const calculateAirshipPower = useCalculateBlueprintProperty('airship');

  const categoryElements = useGetEnchantmentsForAirship(category, 'element');
  const categorySpirits = useGetEnchantmentsForAirship(category, 'spirit');
  const categoryBlueprints = useGetBlueprintsInAirshipCategory(category);

  const pageSize = useGetAirshipCategoryPageSize(category);

  const doCalculations = useCallback(() => {
    const startTime = new Date().getTime();
    const calculatedAirshipPower: AirshipPower[] = [];

    const elements = [...categoryElements, undefined];
    const spirits = [...categorySpirits, undefined];

    /* */
    for (const blueprint of categoryBlueprints) {
      for (const element of elements) {
        for (const spirit of spirits) {
          if (
            (blueprint.builtInSpirit && spirit) ||
            (blueprint.builtInElement && element)
          ) {
            continue;
          }
          const power = calculateAirshipPower(
            blueprint,
            'normal',
            element,
            spirit
          );
          const airshipPowerItem: AirshipPower = {
            id: blueprint.id,
            name: blueprint.name,
            blueprint: blueprint,
            element: element,
            spirit: spirit,
            power,
          };
          calculatedAirshipPower.push(airshipPowerItem);
        }
      }
    }

    calculatedAirshipPower.sort(
      (a: AirshipPower, b: AirshipPower) => b.power - a.power
    );

    const uniqueAirshipPowers = calculatedAirshipPower.reduce(
      (unique: AirshipPower[], o: AirshipPower) => {
        if (!unique.some((obj: AirshipPower) => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      },
      []
    );

    const endTime = new Date().getTime();
    const timeUsed = endTime - startTime;
    setCount(calculatedAirshipPower.length);
    setTimeUsed(timeUsed);
    setAirshipPowers(uniqueAirshipPowers);
  }, [
    categoryBlueprints,
    categoryElements,
    categorySpirits,
    calculateAirshipPower,
  ]);

  useEffect(() => {
    doCalculations();
  }, [doCalculations]);

  const columns = useMemo<MRT_ColumnDef<AirshipPower>[]>(
    () => [
      {
        id: 'asset',
        header: 'Icon',
        accessorKey: 'blueprint.asset',
        Cell: ({ row }) => <CalculateCategoryItemIcon id={row.original.id} />,
        size: 60,
        ...MRTFixedCellSize(),
      },
      {
        header: 'T',
        accessorKey: 'blueprint.tier',
        size: 20,
        ...MRTFixedCellSize({ align: 'right' }),
      },
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Enchants',
        Cell: ({ row }) => (
          <CalculateCategoryEnchants
            airshipPower={row.original}
            elements={categoryElements}
            spirits={categorySpirits}
          />
        ),
        size: 105,
        ...MRTFixedCellSize(),
      },
      {
        header: 'Power',
        accessorKey: 'power',
        Cell: ({ cell }) => <>{cell.getValue<number>().toLocaleString()}</>,
        size: 75,
        ...MRTFixedCellSize({ align: 'right' }),
      },
    ],
    [categoryElements, categorySpirits]
  );

  const table = useMaterialReactTable({
    columns,
    data: airshipPowers,
    layoutMode: 'grid',
    initialState: {
      density: 'compact',
      pagination: {
        pageSize: pageSize,
        pageIndex: 0,
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    enableColumnResizing: false,
    enableToolbarInternalActions: false,
    enableTableHead: false,
    muiTopToolbarProps: { sx: { borderBottom: 1, borderColor: 'divider' } },
    renderTopToolbarCustomActions: airshipCategory
      ? () => <CalculateCategoryTopToolbar airshipCategory={airshipCategory} />
      : undefined,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const elementId = row.original.element?.id;
        const spiritId = row.original.spirit?.id;
        openModal(row.original.id, 'airship', elementId, spiritId);
      },
      sx: { cursor: 'pointer' },
    }),
  });

  useEffect(() => {
    table.setPageSize(pageSize);
  }, [pageSize, table]);

  if (!airshipCategory) return null;

  return (
    <Box
      flexBasis="calc(50% - 16px)"
      // flexGrow="1"
      //  sx={{ maxWidth: '500px' }}
    >
      <MaterialReactTable table={table} />
      <Divider />
      <Box>
        Performed {count.toLocaleString()} permutations in {timeUsed}ms
      </Box>
    </Box>
  );
};

export const MemoizedCalculateCategory = React.memo(CalculateCategory);
export default CalculateCategory;
