import { isEqual } from 'lodash';
import { createSelectorCreator, lruMemoize } from 'reselect';
import { useAppSelector, useBuildAirshipDefaultSettings } from '.';
import { ISettingsAirshipCategories } from '../../features/user/userSlice';
import { RootState } from '../store';

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual);

const usePageSizeSelector = () => {
  const buildAirshipDefaultSettings = useBuildAirshipDefaultSettings();

  return createDeepEqualSelector(
    (state: RootState) => {
      if (state.user.airship) {
        return state.user.airship;
      }
      return buildAirshipDefaultSettings();
    },
    (_: any, category: ISettingsAirshipCategories) => ({
      category,
    }),
    (airshipSettings, { category }) => {
      const categorySelector = airshipSettings.shareSettings ? 'all' : category;
      return airshipSettings.settings[categorySelector].pageSize || 10;
    }
  );
};

const useGetAirshipCategoryPageSize = (
  category: ISettingsAirshipCategories
) => {
  const pageSizeSelector = usePageSizeSelector();

  return useAppSelector(
    createDeepEqualSelector(
      (state: RootState) => pageSizeSelector(state, category),
      (pageSize) => pageSize
    ),
    isEqual
  );
};

export { useGetAirshipCategoryPageSize };
