import { capitalize, isEqual } from 'lodash';
import { createSelectorCreator, lruMemoize } from 'reselect';
import { useBuildAirshipDefaultSettings } from '.';
import { airshipCategories } from '../../features/data/dataHelpers';
import {
  blueprintSelectors,
  BlueprintWithCollection,
} from '../../features/data/dataSlice';
import {
  ISettingsAirshipCategories,
  ISettingsTypeFilter,
  ISettingsFilterTypes,
  collectionSelectors,
  getNewCollection,
} from '../../features/user/userSlice';
import { RootState } from '../store';
import { useAppSelector } from './storeHooks';

// create a "selector creator" that uses lodash isEqual instead of ===
const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual);

const useSettingsSelector = () => {
  const buildAirshipDefaultSettings = useBuildAirshipDefaultSettings();

  return createDeepEqualSelector(
    (state: RootState) => {
      if (state.user.airship) {
        return state.user.airship;
      }
      return buildAirshipDefaultSettings();
    },
    (
      _: any,
      category: ISettingsAirshipCategories,
      type: ISettingsFilterTypes
    ) => ({
      category,
      type,
    }),
    (airshipSettings, { category, type }) => {
      const categorySelector = airshipSettings.shareSettings ? 'all' : category;
      const typeSelector = airshipSettings.settings[categorySelector]
        .shareSettings
        ? 'all'
        : type;
      return airshipSettings.settings[categorySelector].type[typeSelector];
    }
  );
};

const categorySelector = createDeepEqualSelector(
  (state: RootState, category: ISettingsAirshipCategories) => {
    const airshipCategory = airshipCategories.find((ac) => ac.key === category);
    if (!airshipCategory) return [];
    return blueprintSelectors
      .selectAll(state)
      .filter((bp) => bp.airshipCategory === airshipCategory.name)
      .map((bp) => {
        const collection = collectionSelectors.selectById(state, bp.id);
        return { ...bp, ...(collection ?? getNewCollection(bp.id)) };
      });
  },
  (bps) => bps as BlueprintWithCollection[]
);

const typeSelector = createDeepEqualSelector(
  (state: RootState, type: Exclude<ISettingsFilterTypes, 'item'>) => {
    const blueprints: BlueprintWithCollection[] = blueprintSelectors
      .selectAll(state)
      .filter((bp) => bp.type === capitalize(type))
      .map((bp) => {
        const collection = collectionSelectors.selectById(state, bp.id);
        return { ...bp, ...(collection ?? getNewCollection(bp.id)) };
      });
    return blueprints;
  },
  (bps) => bps
);

const filterBlueprints = (
  blueprints: BlueprintWithCollection[],
  settings: ISettingsTypeFilter,
  sort: boolean
) => {
  const filteredBlueprints = blueprints.filter((bp) => {
    const learned =
      bp.learned &&
      bp.tier >= settings.learnedTier[0] &&
      bp.tier <= settings.learnedTier[1];

    const notLearned =
      settings.includeNotlearned &&
      !bp.learned &&
      bp.tier >= settings.notlearnedTier[0] &&
      bp.tier <= settings.notlearnedTier[1];

    return learned || notLearned;
  });
  if (!sort) {
    return filteredBlueprints;
  }

  return filteredBlueprints.sort((a, b) => {
    if (a.tier === b.tier) {
      if (a.learned && !b.learned) {
        return -1;
      } else if (!a.learned && b.learned) {
        return 1;
      } else {
        return 0;
      }
    }
    return a.tier - b.tier;
  });
};

const useGetBlueprintsInAirshipCategory = (
  category: ISettingsAirshipCategories
) => {
  const settingsSelector = useSettingsSelector();
  return useAppSelector(
    createDeepEqualSelector(
      (state: RootState) => categorySelector(state, category),
      (state: RootState) => settingsSelector(state, category, 'item'),
      (bps, settings) => filterBlueprints(bps, settings, false)
    ),
    isEqual
  );
};

const useGetEnchantmentsForAirship = (
  category: ISettingsAirshipCategories,
  type: Exclude<ISettingsFilterTypes, 'item'>
) => {
  const settingsSelector = useSettingsSelector();
  return useAppSelector(
    createDeepEqualSelector(
      (state: RootState) => typeSelector(state, type),
      (state: RootState) => settingsSelector(state, category, type),
      (bps, settings) => filterBlueprints(bps, settings, true)
    ),
    isEqual
  );
};

export { useGetBlueprintsInAirshipCategory, useGetEnchantmentsForAirship };
