import { Box, Button } from '@mui/material';
import { isEqual } from 'lodash';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { saveUser } from '../user/userSlice';
import WorkerTable from './WorkerTable';
import InputLevel from './InputLevel';

const Merchant = () => {
  const dispatch = useAppDispatch();
  const merchant = useAppSelector((state) => state.user.data, isEqual);

  const onSubmit = (values: any) => {
    dispatch(saveUser(values));
  };

  const maxValue = useAppSelector((state) =>
    state.data.merchantLevels.reduce(
      (max, item) => (item.level > max ? item.level : max),
      0
    )
  );
  const minValue = useAppSelector((state) =>
    state.data.merchantLevels.reduce(
      (min, item) => (item.level < min ? item.level : min),
      99999
    )
  );

  return (
    <Box>
      <Form
        initialValues={merchant}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" gap={2} flexDirection="column">
              <TextField name="name" label="Name" helperText="Your name" />
              {/* <TextField
              name="hashtag"
              label="Userid"
              helperText="The number after the #"
            /> */}
              <InputLevel
                name="level"
                label="Merchant level"
                value={values.level}
                minValue={minValue}
                maxValue={maxValue}
                setValue={(value) => form.change('level', value)}
                sx={{ width: '140px' }}
              />
              <Box>
                <Button type="submit" variant="outlined">
                  Save Merchant
                </Button>
              </Box>
              {/* <TextField
              name="guild"
              label="Guild"
              helperText="Name of the guild you are in"
            /> */}
              <WorkerTable />
              {/* <Divider /> */}
              {/* <WorkersList /> */}
            </Box>
          </form>
        )}
      />{' '}
    </Box>
  );
};

export default Merchant;
