import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormApi } from 'final-form';
import { isEqual } from 'lodash';
import { Switches } from 'mui-rff';
import { Form } from 'react-final-form';
import {
  useAppDispatch,
  useAppSelector,
  useBuildAirshipDefaultSettings,
} from '../../app/hooks';
import AssetBox from '../asset/AssetBox';
import {
  useCloseAirshipSettingsModal,
  useOpenAirshipSettingsModal,
} from '../core/hooks';
import SettingsForm from '../core/SettingsForm';
import { airshipCategories } from '../data/dataHelpers';
import {
  ISettingsAirshipCategories,
  saveAirshipSettings,
} from '../user/userSlice';

export const airshipSettingsFormCategories = [
  ...airshipCategories,
  { key: 'all' },
] as typeof airshipCategories;

const TabIcon: React.FC<{ category: string }> = ({ category }) => {
  return (
    <AssetBox type="filterType" search={category} width="24px" height="24px" />
  );
};

const AirshipSettingsModal = () => {
  const open = useAppSelector((state) => state.core.airshipSettingsModal.open);
  const tab = useAppSelector((state) => state.core.airshipSettingsModal.tab);
  const closeAirshipSettingsModal = useCloseAirshipSettingsModal();
  const openAirshipSettingsModal = useOpenAirshipSettingsModal();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const buildAirshipDefaultSettings = useBuildAirshipDefaultSettings();
  const dispatch = useAppDispatch();
  const defaultValues = buildAirshipDefaultSettings();

  const handleDialogClose = () => {
    closeAirshipSettingsModal();
  };
  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: ISettingsAirshipCategories
  ) => {
    openAirshipSettingsModal(newValue);
  };

  const initialValues = useAppSelector((state) => {
    if (state.user.airship) {
      return state.user.airship;
    }
    return defaultValues;
  }, isEqual);

  const handleSubmitForm = (values: typeof initialValues) => {
    closeAirshipSettingsModal();
    return dispatch(saveAirshipSettings(values));
  };

  const handleSetDefault = (form: FormApi<typeof initialValues>) => {
    if (isEqual(initialValues, defaultValues)) {
      form.reset();
    } else {
      form.change('shareSettings', defaultValues.shareSettings);
      form.change('settings', defaultValues.settings);
    }
  };

  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      render={({ handleSubmit, values, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="form-dialog-title"
            scroll="paper"
            disablePortal={true}
            fullScreen={matches}
            PaperProps={{
              sx: { minWidth: '450px' },
            }}
          >
            <DialogTitle id="form-dialog-title">
              <Box display="flex" justifyContent="space-between">
                <Box>Settings</Box>
                <Switches
                  name="shareSettings"
                  formControlLabelProps={{ labelPlacement: 'start' }}
                  data={{ label: 'All', value: true }}
                />
              </Box>
              {values?.shareSettings ? null : (
                <Tabs
                  value={tab}
                  onChange={handleChangeTab}
                  centered
                  sx={{
                    transitionDuration: '0s',
                    display: values?.shareSettings ? 'none' : '',
                  }}
                >
                  {airshipCategories.map((c) => (
                    <Tab
                      key={c.key}
                      //   label={currTab === 'weapons' ? 'Weapons' : ''}
                      value={c.key}
                      icon={<TabIcon category={c.assetName} />}
                      iconPosition="start"
                    />
                  ))}
                </Tabs>
              )}
              {values?.shareSettings ? (
                <Tabs value="all" centered>
                  <Tab label="All categories" value="all" />
                </Tabs>
              ) : null}
            </DialogTitle>
            <DialogContent>
              <SettingsForm
                name={`settings.${values?.shareSettings ? 'all' : tab}.type`}
                pageSizeName={`settings.${
                  values?.shareSettings ? 'all' : tab
                }.pageSize`}
              />
            </DialogContent>
            <DialogActions>
              <Button color="warning" onClick={handleDialogClose}>
                Cancel
              </Button>
              <Button color="warning" onClick={() => handleSetDefault(form)}>
                Default
              </Button>
              <Button
                onClick={form.reset as any}
                disabled={submitting || pristine}
              >
                Reset
              </Button>
              <Button type="submit" disabled={submitting || pristine}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    />
  );
};

export default AirshipSettingsModal;
