import { useState } from 'react';
import { Avatar, Box, Button, SxProps, Theme, Tooltip } from '@mui/material';

import {
  useAppSelector,
  useGetBlueprintSearch,
  useGetCollectionProp,
  useGetMerchantMaxMarketTier,
  useSelectBlueprintId,
} from '../../app/hooks';
import BlueprintModalSelectEnchantmentPopover from './BlueprintModalSelectEnchantmentPopover';
import AssetBox from '../asset/AssetBox';
import AssetBadge, { AssetBadgeProps } from '../asset/AssetBadge';
import { blueprintSelector } from './BlueprintModal';
import { capitalize } from 'lodash';

type Props = {
  enchantmentType: 'element' | 'spirit';
  // enchantmentId: EntityId | undefined;
  // setEnchantment: React.Dispatch<React.SetStateAction<EntityId | undefined>>;
  affinity?: string;
  builtIn?: string;
};

const enchantmentStyles: SxProps<Theme> = {
  width: '120px',
  height: '120px',
  borderColor: 'primary.main',
  borderWidth: '2px',
  borderStyle: 'solid',
  '&.MuiButton-root.Mui-disabled': {
    borderColor: 'text.disabled',
    borderWidth: '2px',
    borderStyle: 'solid',
  },
};

const BlueprintModalSelectEnchantment: React.FC<Props> = ({
  enchantmentType,
  affinity,
  builtIn,
}) => {
  const merchantMaxMarketItemTier = useGetMerchantMaxMarketTier();
  const selectId = useSelectBlueprintId();
  const enchantment = useAppSelector((s) =>
    blueprintSelector(s, enchantmentType)
  );

  const learned = useGetCollectionProp(enchantment?.id || '', 'learned');
  const builtInEnchantment = useGetBlueprintSearch([
    { prop: 'name', search: builtIn || '' },
    { prop: 'type', search: capitalize(enchantmentType) },
  ]);
  const affinityAssetType =
    enchantmentType === 'element' ? 'elementAffinity' : 'spiritAffinity';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const disabled = !!builtIn;
  const showBadge = !learned && !disabled;

  const assetType =
    enchantmentType === 'element' ? 'elementEnchanted' : 'spiritAffinity';

  const affinityAssetSearch = builtIn
    ? enchantmentType === 'element'
      ? builtInEnchantment?.elementAffinity
      : builtIn
    : affinity;

  const isAffinity = enchantment
    ? enchantmentType === 'element'
      ? enchantment[affinityAssetType] === affinity || affinity === 'All'
      : enchantment[affinityAssetType]?.split(' ')[0] ===
        affinity?.split(' ')[0]
    : false;

  let assetBadgeProps: AssetBadgeProps = { showBadge: false, src: '' };
  if (enchantment && showBadge) {
    if (enchantment.tier > merchantMaxMarketItemTier) {
      assetBadgeProps = {
        showBadge: true,
        search: 'None',
        type: 'marketFilter',
      };
    } else {
      assetBadgeProps = { showBadge: true, search: 'Trade', type: 'miscIcons' };
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="column" mt={2}>
        <Button
          sx={enchantmentStyles}
          onClick={handleClick}
          disabled={disabled}
        >
          {enchantment || disabled ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <AssetBadge {...assetBadgeProps} width="35px" height="35px">
                <Avatar
                  variant="square"
                  sx={{
                    '&': { backgroundColor: 'transparent' },
                    width: '65px',
                    height: '65px',
                    overflow: 'visible',
                  }}
                >
                  <AssetBox
                    width="65px"
                    height="65px"
                    type={assetType}
                    search={disabled ? builtIn : enchantment?.name || ''}
                    shadowSize={2}
                    shadow={
                      isAffinity ? 'affinity' : disabled ? 'builtin' : false
                    }
                    sx={{ mt: 1 }}
                  />
                </Avatar>
              </AssetBadge>
              {/* <Box> */}
              <Box sx={{ mt: 1 }}>
                {(builtIn || enchantment?.name)?.split(' ')[0]}
              </Box>
            </Box>
          ) : (
            <Box>Select</Box>
          )}
        </Button>
        {affinityAssetSearch ? (
          <Tooltip
            arrow
            PopperProps={{
              sx: { maxWidth: '150px', textAlign: 'center' },
            }}
            title={affinity || builtInEnchantment?.elementAffinity || builtIn}
          >
            <Box display="flex" gap={1} mt={0.5} justifyContent="center">
              <AssetBox
                width="25px"
                height="25px"
                type={affinityAssetType}
                search={affinityAssetSearch}
              />
              <Box textAlign="center">Affinity</Box>
            </Box>
          </Tooltip>
        ) : null}
      </Box>
      <BlueprintModalSelectEnchantmentPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        enchantmentType={enchantmentType}
        selected={selectId(enchantment)}
      />
    </>
  );
};

export default BlueprintModalSelectEnchantment;
