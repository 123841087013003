import { Avatar, Box, useMediaQuery, useTheme } from '@mui/material';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { useGetBlueprintsWithCollection } from '../../app/hooks';
import AssetBox, { IDropShadowColorTypes } from '../asset/AssetBox';
import { MRTFixedCellSize } from '../core/coreHelpers';
import { useOpenBlueprintModal } from '../core/hooks';
import { BlueprintWithCollection } from '../data/dataSlice';

interface IPrecrafts {
  blueprint: BlueprintWithCollection;
  component: BlueprintWithCollection;
  qualityId: 'componentQuality1' | 'componentQuality2';
  amountId: 'amountNeeded1' | 'amountNeeded2';
  ascension?: BlueprintWithCollection[
    | 'ascensionUpgrade1'
    | 'ascensionUpgrade2'
    | 'ascensionUpgrade3'];
}

interface IiconPropsFn {
  (isComponent?: boolean): Omit<MRT_ColumnDef<IPrecrafts>, 'header' | 'id'>;
}

const iconProps: IiconPropsFn = (isComponent = false) => ({
  Cell: ({ cell, row }) => {
    const asset = cell.getValue<string>();
    if (!asset) return null;
    const quality = row.original.blueprint[
      row.original.qualityId
    ]?.toLowerCase() as IDropShadowColorTypes;
    return (
      <Avatar
        variant="square"
        sx={{ '&': { backgroundColor: 'transparent', overflow: 'unset' } }}
      >
        <AssetBox
          width="100%"
          height="100%"
          src={asset}
          shadow={isComponent ? quality : undefined}
        />
      </Avatar>
    );
  },
  size: 60,
  ...MRTFixedCellSize(),
  Header: false,
  enableColumnFilter: false,
  enableColumnActions: false,
  enableColumnDragging: false,
  enableSorting: false,
  enableGlobalFilter: false,
});

const findAscension = (
  blueprint: BlueprintWithCollection,
  component: BlueprintWithCollection
) => {
  if (
    blueprint.ascensionUpgrade1?.includes(component.name) ||
    blueprint.ascensionUpgrade1 === 'Component Quality Reduced'
  ) {
    return blueprint.ascensionUpgrade1;
  }
  if (
    blueprint.ascensionUpgrade2?.includes(component.name) ||
    blueprint.ascensionUpgrade2 === 'Component Quality Reduced'
  ) {
    return blueprint.ascensionUpgrade2;
  }
  if (
    blueprint.ascensionUpgrade3?.includes(component.name) ||
    blueprint.ascensionUpgrade3 === 'Component Quality Reduced'
  ) {
    return blueprint.ascensionUpgrade3;
  }
};

const PreCraft = () => {
  const openModal = useOpenBlueprintModal();
  const blueprints = useGetBlueprintsWithCollection();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  let precrafts: IPrecrafts[] = [];

  for (const blueprint of blueprints) {
    // if (
    //   ['Element', 'Spirit', 'Moonstone', 'Runestone'].includes(blueprint.type)
    // ) {
    //   continue;
    // }
    const component1 = blueprints.find((bp) => {
      return bp.name === blueprint.component1;
    });
    const component2 = blueprints.find((bp) => {
      return bp.name === blueprint.component2;
    });

    if (component1) {
      precrafts.push({
        blueprint,
        component: component1,
        qualityId: 'componentQuality1',
        amountId: 'amountNeeded1',
        ascension: findAscension(blueprint, component1),
      });
    }
    if (component2) {
      precrafts.push({
        blueprint,
        component: component2,
        qualityId: 'componentQuality2',
        amountId: 'amountNeeded2',
        ascension: findAscension(blueprint, component2),
      });
    }
  }

  const columns = useMemo<MRT_ColumnDef<IPrecrafts>[]>(
    () => [
      {
        id: 'asset',
        accessorKey: 'blueprint.asset',
        header: 'Icon',
        ...iconProps(),
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'blueprint.name',
      },
      {
        id: 'component.asset',
        header: 'Component Icon',
        accessorKey: 'component.asset',
        ...iconProps(true),
      },
      {
        id: 'amount',
        header: 'Amount',
        accessorFn: (row) => {
          return row.blueprint[row.amountId];
        },
        Cell: ({ cell }) => {
          const amount = cell.getValue<number>();
          return <Box>x {amount}</Box>;
        },
        size: 40,
        ...MRTFixedCellSize(),
        Header: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableSorting: false,
      },
      {
        id: 'component',
        header: 'Component',
        accessorKey: 'component.name',
      },
      {
        header: 'Ascension',
        accessorKey: 'ascension',
      },
    ],
    []
  );

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={precrafts}
        layoutMode="grid"
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableStickyHeader
        initialState={{
          density: 'compact',
          columnPinning: { left: matches ? ['asset'] : ['asset', 'name'] },
          pagination: { pageIndex: 0, pageSize: 50 },
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: 'calc(100vh - 240px)',
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            openModal(row.original.blueprint.id);
          },
          sx: { cursor: 'pointer' },
        })}
      />
    </Box>
  );
};

export default PreCraft;
