import produce from 'immer';
import { merge, keyBy, values, pickBy, camelCase } from 'lodash';
import { Blueprint } from './dataSlice';

export type AirshipCategory = {
  name: Blueprint['airshipCategory'];
  assetName: string;
  key: 'weapons' | 'bodyArmor' | 'miscArmor' | 'accessories';
  types: Blueprint['type'][];
};

export const airshipCategories: AirshipCategory[] = [
  {
    name: 'Weapons',
    assetName: 'Weapons',
    key: 'weapons',
    types: [
      'Axe',
      'Bow',
      'Crossbow',
      'Dagger',
      'Gun',
      'Instrument',
      'Mace',
      'Spear',
      'Staff',
      'Sword',
      'Wand',
    ],
  },
  {
    name: 'Body Armor',
    assetName: 'Armor',
    key: 'bodyArmor',
    types: ['Clothes', 'Heavy Armor', 'Light Armor'],
  },
  {
    name: 'Misc Armor',
    assetName: 'Armor2',
    key: 'miscArmor',
    types: [
      'Gauntlets',
      'Gloves',
      'Heavy Footwear',
      'Helmet',
      'Light Footwear',
      'Magician Hat',
      'Rogue Hat',
    ],
  },
  {
    name: 'Accessories',
    assetName: 'Accessories',
    key: 'accessories',
    types: [
      'Amulet',
      'Aurasong',
      'Cloak',
      'Dessert',
      'Herbal Medicine',
      'Meal',
      'Potion',
      'Ring',
      'Shield',
      'Spell',
    ],
  },
];

export const getAirshipCategory = (
  type: Blueprint['type']
): Blueprint['airshipCategory'] => {
  const category = airshipCategories.find((c) => c.types.includes(type));
  if (category) {
    return category.name;
  }
  return undefined;
};

const clearThousandSeparator = (str: string) => {
  return str.replace(/\b(?:[0-9]{1,3},[0-9]{3}(?:,[0-9]{3})*)\b/, (match) => {
    return match.replace(/,/g, '');
  });
};

const clearPercentageSign = (str: string) => {
  return str.replace(/^\d+%$/, (match) => {
    return match.replace(/%/g, '');
  });
};

export const transform = (value: string, header: string | number) => {
  if (value === 'Loading...') return 0;
  if (value === '---' || value === '' || header === '' || value === null)
    return undefined;
  return clearThousandSeparator(clearPercentageSign(value));
};

export const cleanObject = <T extends {}>(obj: T) => {
  return pickBy(obj, (val) => val !== '' && val !== undefined) as T;
};

export const cleanObjectsInArray = <T extends {}>(cleanArray: T[]) =>
  cleanArray.map((obj) => cleanObject<T>(obj));

export interface AssetsData {
  asset: string;
  route: string;
}

type GetAssetFn = (
  assets: AssetsData[],
  search: string | ((asset: AssetsData) => boolean),
  route: string
) => string | undefined;

export const getAsset: GetAssetFn = (assets, search, route) => {
  const asset = assets.find(
    (a) =>
      (a.route.startsWith(route) || a.route.startsWith(`/Custom${route}`)) &&
      !a.route.endsWith('_d.png') &&
      !a.route.endsWith('_g.png') &&
      (typeof search === 'function' ? search(a) : a.asset === search)
  );
  if (asset) {
    return '/assets' + asset.route;
  }
  return undefined;
};

export type NerileaData = {
  name: string;
  attack: number;
  defense: number;
  health: number;
  evasion: number;
  criticalHitChance: number;
};

export const combineArrays = (
  kabam: Blueprint[],
  nerilea: NerileaData[],
  assets: AssetsData[]
) => {
  nerilea = nerilea.filter(
    (elem) => elem.name !== 'Fresh Spirit' && elem.name !== "Mundra's Spirit"
  );

  let merged;
  if (kabam.length > nerilea.length) {
    merged = merge(keyBy(kabam, 'name'), keyBy(nerilea, 'name'));
  } else {
    merged = merge(keyBy(nerilea, 'name'), keyBy(kabam, 'name'));
  }
  const mergedBlueprints: Blueprint[] = values(merged);

  return produce(mergedBlueprints, (draft) => {
    draft.forEach((kElm) => {
      // const elem2 = nerilea.find((elem) => elem.name === kElm.name);

      kElm.id = camelCase('t' + kElm.tier + kElm.type + kElm.name);
      kElm.asset = getAsset(assets, kElm.name, '/Items/');

      if (kElm.type === 'Element') {
        kElm.assetEnchantment = getAsset(
          assets,
          kElm.name,
          '/Elements/Enchantment/'
        );
      }
      const searchFn = (asset: AssetsData) =>
        asset.asset.startsWith(kElm.name.split(' ')[0]);
      if (kElm.type === 'Spirit') {
        kElm.assetEnchantment = getAsset(
          assets,
          searchFn,
          '/Skills/Enchantment/'
        );
      }

      const airshipCategory = getAirshipCategory(kElm.type);
      if (airshipCategory) {
        kElm.airshipCategory = airshipCategory;
      } else {
        kElm.airshipPower = 0;
      }

      // Object.assign(kElm, elem2);
      // nerilea = nerilea.filter((elem) => elem.name !== kElm.name);
    });
  });
};
