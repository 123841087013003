import { blueprintSelectors } from '../../features/data/dataSlice';
import { useAppSelector } from './';

const useGetBlueprints = () => {
  return useAppSelector((state) => {
    return blueprintSelectors.selectAll(state);
  });
};

export { useGetBlueprints };
