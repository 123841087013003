import { Box, Button, SxProps, Theme } from '@mui/material';

import {
  useAppSelector,
  useCalculateBlueprintProperty,
  useGetAsset,
} from '../../app/hooks';
import AssetBox from '../asset/AssetBox';
import { blueprintSelector } from './BlueprintModal';

const BlueprintModalCalculateAirshipPower = () => {
  const blueprint = useAppSelector((s) => blueprintSelector(s, 'blueprint'));
  const element = useAppSelector((s) => blueprintSelector(s, 'element'));
  const spirit = useAppSelector((s) => blueprintSelector(s, 'spirit'));
  const quality = useAppSelector((s) => s.core.blueprintModal.quality);

  const airpowerAsset = useGetAsset('Airship Power', 'statIndicator');
  const calculateAirshipPower = useCalculateBlueprintProperty('airship');
  const calculateValue = useCalculateBlueprintProperty('value');

  const airshipPower = calculateAirshipPower(
    blueprint,
    quality,
    element,
    spirit
  );

  const bpValue = calculateValue(blueprint, quality, element, spirit);

  const assetBoxSx: SxProps<Theme> = { width: '35px', height: '35px' };
  const size = { width: '35px', height: '35px' };

  return (
    <Box display="flex" flexDirection="column">
      {process.env.NODE_ENV !== 'production' && (
        <Button
          variant="outlined"
          sx={{ position: 'absolute', top: 405, right: 10 }}
          onClick={() => {
            console.group();
            console.log('blueprint', blueprint?.id);
            console.log(blueprint);
            console.log('element', element?.id);
            console.log(element);
            console.log('spirit', spirit?.id);
            console.log(spirit);
            console.groupEnd();
          }}
        >
          LOG
        </Button>
      )}
      {blueprint?.airshipCategory ? (
        <Box
          textAlign="center"
          component="h1"
          sx={{ mt: 1, mb: 1, color: 'warning.main' }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            {airpowerAsset ? (
              <AssetBox {...size} src={airpowerAsset} sx={assetBoxSx} />
            ) : null}
            <Box>{airshipPower.toLocaleString()}</Box>
            {airpowerAsset ? (
              <AssetBox {...size} src={airpowerAsset} sx={assetBoxSx} />
            ) : null}
          </Box>
        </Box>
      ) : null}
      <Box
        textAlign="center"
        component="h1"
        sx={{ mt: 1, mb: 1, color: 'warning.main' }}
      >
        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
          <AssetBox {...size} search="Gold" type="currency" sx={assetBoxSx} />
          <Box>{bpValue.toLocaleString()}</Box>
          <AssetBox {...size} search="Gold" type="currency" sx={assetBoxSx} />
        </Box>
      </Box>
    </Box>
  );
};

export default BlueprintModalCalculateAirshipPower;
