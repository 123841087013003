import { Field, FieldProps } from 'react-final-form';
import {
  FormControl,
  FormControlProps,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
} from '@mui/material';
import {
  ErrorMessage,
  ShowErrorFunc,
  showErrorOnChange,
  useFieldForErrors,
} from 'mui-rff';

export interface SliderProps extends Partial<Omit<MuiSliderProps, 'onChange'>> {
  name: string;
  label?: string | number | React.ReactElement;
  required?: boolean;
  helperText?: React.ReactNode;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  formLabelProps?: Partial<FormLabelProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  showError?: ShowErrorFunc;
}

/**
 *
 * Demos:
 *
 * - [Slider](https://mui.com/material-ui/react-slider/)
 *
 * API:
 *
 * - [Slider API](https://mui.com/material-ui/api/slider/)
 * - inherits [SliderUnstyled API](https://mui.com/base/api/slider-unstyled/)
 */
const Slider: React.FC<SliderProps> = ({
  name,
  label,
  required,
  helperText,
  fieldProps,
  formControlProps,
  formLabelProps,
  formHelperTextProps,
  showError = showErrorOnChange,
  ...restSlider
}) => {
  const field = useFieldForErrors(name);
  const isError = showError(field);

  return (
    <FormControl required={required} error={isError} {...formControlProps}>
      {label ? <FormLabel {...formLabelProps}>{label}</FormLabel> : <></>}
      <Field
        type="slider"
        name={name}
        render={({ input: { name, value, onChange, ...restInput } }) => (
          <MuiSlider
            name={name}
            value={value}
            onChange={onChange}
            {...restSlider}
            {...restInput}
          />
        )}
        {...fieldProps}
      />
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText as any}
      />
    </FormControl>
  );
};

export default Slider;
