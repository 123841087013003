import { Box, SxProps, Theme, Typography } from '@mui/material';
import AssetBox from '../asset/AssetBox';

interface Props {
  children: React.ReactNode;
  width?: string;
  sx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  bannerSx?: SxProps<Theme>;
  typographySx?: SxProps<Theme>;
}

const Banner: React.FC<Props> = ({
  children,
  width = '394px',
  containerSx,
  bannerSx,
  typographySx,
}) => {
  const height =
    Math.round(Number(width.replace('px', '')) * 0.1751269036) + 'px';
  const defaultBannerSx: SxProps<Theme> = {
    position: 'relative',
    width,
    height,
  };
  const newBannerSx: SxProps<Theme> = { ...defaultBannerSx, ...bannerSx };

  // const { width: typeWidth, height: typoHJeight } = newBannerSx;

  const defaultTypographySx: SxProps<Theme> = {
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    width,
    height,
    zIndex: 2,
    // position: 'relative',
    // backgroundImage: 'url("/assets/item-banner.png")',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'stretch',
    color: '#fff3d6',
    pt: 1.5,
    filter: 'drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.5))',
    textShadow:
      '1px 1px 0 rgba(31,22,31,0.2),' +
      '-1px 1px 0 rgba(31,22,31,0.2),' +
      '1px -1px 0 rgba(31,22,31,0.2),' +
      '-1px -1px 0 rgba(31,22,31,0.2),' +
      '0px 1px 0 rgba(31,22,31,0.2),' +
      '0px -1px 0 rgba(31,22,31,0.2),' +
      '-1px 0px 0 rgba(31,22,31,0.2),' +
      '1px 0px 0 rgba(31,22,31,0.2),' +
      '2px 2px 0 rgba(31,22,31,0.2),' +
      '-2px 2px 0 rgba(31,22,31,0.2)' +
      ',2px -2px 0 rgba(31,22,31,0.2)' +
      ',-2px -2px 0 rgba(31,22,31,0.2),' +
      '0px 2px 0 rgba(31,22,31,0.2),' +
      '0px -2px 0 rgba(31,22,31,0.2),' +
      '-2px 0px 0 rgba(31,22,31,0.2),' +
      '2px 0px 0 rgba(31,22,31,0.2),' +
      '1px 2px 0 rgba(31,22,31,0.2),' +
      '-1px 2px 0 rgba(31,22,31,0.2),' +
      '1px -2px 0 rgba(31,22,31,0.2),' +
      '-1px -2px 0 rgba(31,22,31,0.2),' +
      '2px 1px 0 rgba(31,22,31,0.2),' +
      '-2px 1px 0 rgba(31,22,31,0.2),' +
      '2px -1px 0 rgba(31,22,31,0.2),' +
      '-2px -1px 0 rgba(31,22,31,0.2),' +
      '0 3px 0 rgba(54,54,54,0.25),' +
      '-1px 3px 0 rgba(54,54,54,0.25),' +
      '1px 3px 0 rgba(54,54,54,0.25);',
  };

  const defaultContainerSx: SxProps<Theme> = {
    position: 'relative',
    m: 0,
    p: 0,
  };

  return (
    <Box sx={{ ...defaultContainerSx, ...containerSx }}>
      <AssetBox src="/assets/item-banner.png" sx={newBannerSx} />
      <Typography
        component="h2"
        variant="h6"
        sx={{ ...defaultTypographySx, ...typographySx }}
      >
        {children}
      </Typography>
    </Box>
  );

  /*
  return (
    <Typography
      id="modal-modal-title"
      variant="h6"
      component="h2"
      sx={{ ...defaultSx, ...sx }}
    >
      {children}
    </Typography>
  );
  //*/
};

export default Banner;
