import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
} from '@reduxjs/toolkit';
import { NonUndefined } from 'tsdef';
import { AppThunk, RootState } from '../../app/store';
import { NumberRange } from '../../app/tsdefs';
import {
  importDataAPI,
  saveAirshipSettingsAPI,
  saveKingBaitQualityAPI,
  saveKingbaitSettingsAPI,
  saveUserWorkerAPI,
  setThemeModeAPI,
  toggleCollectionQualityCollectedAPI,
  togglePropCollectionAPI,
} from './userAPI';

interface User {
  name: string;
  hashtag?: number;
  level: NumberRange<1, 99>;
  guild: string;
}

export type Collection = {
  id: EntityId;
  learned: boolean;
  mastered: boolean;
  collected: {
    normal: boolean;
    superior: boolean;
    flawless: boolean;
    epic: boolean;
    legendary: boolean;
  };
};
export type CollectedQualityTypes = keyof Collection['collected'];

export const collectionAdapter = createEntityAdapter<Collection>();
export const collectionSelectors = collectionAdapter.getSelectors<RootState>(
  (state) => state.user.collection
);

export const getNewCollection = (id: EntityId) => {
  const newCollection: Collection = {
    id,
    learned: false,
    mastered: false,
    collected: {
      normal: false,
      superior: false,
      flawless: false,
      epic: false,
      legendary: false,
    },
  };
  return newCollection;
};

export interface IUserWorker {
  id: EntityId;
  unlocked: boolean;
  level: number;
}

export const userWorkerAdapter = createEntityAdapter<IUserWorker>();
export const userWorkerSelectors = userWorkerAdapter.getSelectors<RootState>(
  (state) => state.user.workers
);

export const getNewUserWorker = (id: EntityId) => {
  const newWorker: IUserWorker = {
    id,
    unlocked: false,
    level: 1,
  };
  return newWorker;
};

export type ISettingsAirshipCategories =
  | 'weapons'
  | 'bodyArmor'
  | 'miscArmor'
  | 'accessories';
export type ISettingsFilterTypes = 'item' | 'element' | 'spirit';

export interface ISettingsTypeFilter {
  learnedTier: [NumberRange<1, 50>, NumberRange<1, 50>];
  includeNotlearned: boolean;
  notlearnedTier: [NumberRange<1, 50>, NumberRange<1, 50>];
}

export type ISettingsTypeFilters = {
  [key in ISettingsFilterTypes | 'all']: ISettingsTypeFilter;
};

export interface UserInitialState {
  mode: 'light' | 'dark' | 'system';
  data: User;
  airship?: {
    shareSettings: boolean;
    settings: {
      [key in ISettingsAirshipCategories | 'all']: {
        shareSettings: boolean;
        type: ISettingsTypeFilters;
        pageSize: number;
      };
    };
  };
  kingBait?: {
    quality: Exclude<CollectedQualityTypes, 'normal'>;
    settings?: {
      shareSettings: boolean;
      type: ISettingsTypeFilters;
    };
  };
  collection: ReturnType<typeof collectionAdapter.getInitialState>;
  workers: ReturnType<typeof userWorkerAdapter.getInitialState>;
}

const initialState: UserInitialState = {
  mode: 'system',
  data: {
    name: '',
    level: 1,
    guild: '',
  },
  collection: collectionAdapter.getInitialState(),
  workers: userWorkerAdapter.getInitialState(),
};

export type CollectionToggleProp = 'learned' | 'mastered';

export const toggleProp = createAsyncThunk(
  'user/collection/toggleProp',
  async ({
    collection,
    prop,
  }: {
    collection: Collection;
    prop: CollectionToggleProp;
  }) => {
    const response = await togglePropCollectionAPI(collection, prop);
    return response;
  }
);

export const collectionToggleProp =
  (id: EntityId, prop: CollectionToggleProp): AppThunk =>
  (dispatch, getState) => {
    const collection =
      collectionSelectors.selectById(getState(), id) ?? getNewCollection(id);
    dispatch(toggleProp({ collection, prop }));
  };

type ToggleCollectedParamaters = {
  collection: Collection;
  quality: CollectedQualityTypes;
};

export const toggleQualityCollected = createAsyncThunk(
  'user/collection/toggleCollected',
  async ({ collection, quality }: ToggleCollectedParamaters) => {
    const response = await toggleCollectionQualityCollectedAPI(
      collection,
      quality
    );
    return response;
  }
);

export const collectionToggleQualityCollected =
  (id: EntityId, quality: CollectedQualityTypes): AppThunk =>
  (dispatch, getState) => {
    const collection = collectionSelectors.selectById(getState(), id);

    if (!collection) return null;

    dispatch(toggleQualityCollected({ collection, quality }));
  };

export const saveAirshipSettings = createAsyncThunk(
  'user/saveAirshipSettings',
  async (settings: NonUndefined<UserInitialState['airship']>) => {
    const response = await saveAirshipSettingsAPI(settings);
    return response;
  }
);

export const saveKingbaitSettingsAsync = createAsyncThunk(
  'user/saveKingBaitSettings',
  async (settings: NonUndefined<UserInitialState['kingBait']>['settings']) => {
    const response = await saveKingbaitSettingsAPI(settings);
    return response;
  }
);

export const saveKingBaitQualityAsync = createAsyncThunk(
  'user/saveKingBaitQuality',
  async (quality: NonUndefined<UserInitialState['kingBait']>['quality']) => {
    const response = await saveKingBaitQualityAPI(quality);
    return response;
  }
);

const saveUserWorkerAsync = createAsyncThunk(
  'user/saveWorker',
  async (worker: IUserWorker) => {
    const response = await saveUserWorkerAPI(worker);
    return response;
  }
);

export const toggleWorkerUnlocked =
  (workerId: EntityId): AppThunk =>
  (dispatch, getState) => {
    const worker =
      userWorkerSelectors.selectById(getState(), workerId) ??
      getNewUserWorker(workerId);
    if (!worker) return null;
    dispatch(saveUserWorkerAsync({ ...worker, unlocked: !worker.unlocked }));
  };

export const saveUserWorkerLevel =
  (workerId: EntityId, level: number): AppThunk =>
  (dispatch, getState) => {
    const worker =
      userWorkerSelectors.selectById(getState(), workerId) ??
      getNewUserWorker(workerId);
    if (!worker) return null;
    dispatch(saveUserWorkerAsync({ ...worker, level }));
  };

export interface IImportData {
  merchant: User;
  collection: Collection[];
  workers: IUserWorker[];
}

export const importDataAsync = createAsyncThunk(
  'data/import',
  async (data: IImportData) => {
    const response = await importDataAPI(data);
    return response;
  }
);

export const setThemModeAsync = createAsyncThunk(
  'user/setThemeMode',
  async (mode: UserInitialState['mode']) => {
    const response = await setThemeModeAPI(mode);
    return response;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action) => {
      state.data = action.payload;
    },
    saveCollection: (state, action) => {
      collectionAdapter.upsertMany(state.collection, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleProp.fulfilled, (state, action) => {
        collectionAdapter.upsertOne(state.collection, action.payload);
      })
      .addCase(toggleQualityCollected.fulfilled, (state, action) => {
        collectionAdapter.upsertOne(state.collection, action.payload);
      })
      .addCase(saveAirshipSettings.fulfilled, (state, action) => {
        state.airship = action.payload;
      })
      .addCase(saveKingbaitSettingsAsync.fulfilled, (state, action) => {
        if (state.kingBait) {
          state.kingBait.settings = action.payload;
        } else {
          state.kingBait = {
            quality: 'superior',
            settings: action.payload,
          };
        }
      })
      .addCase(saveKingBaitQualityAsync.fulfilled, (state, action) => {
        if (state.kingBait) {
          state.kingBait.quality = action.payload;
        } else {
          state.kingBait = {
            quality: action.payload,
          };
        }
      })
      .addCase(saveUserWorkerAsync.fulfilled, (state, action) => {
        userWorkerAdapter.upsertOne(state.workers, action.payload);
      })
      .addCase(setThemModeAsync.fulfilled, (state, action) => {
        state.mode = action.payload;
      })
      .addCase(importDataAsync.fulfilled, (state, action) => {
        const { merchant, collection, workers } = action.payload;
        if (merchant) state.data = merchant;
        if (collection) {
          collectionAdapter.upsertMany(state.collection, collection);
        }
        if (workers) userWorkerAdapter.upsertMany(state.workers, workers);
      });
  },
});

export const { saveUser, saveCollection } = userSlice.actions;

export default userSlice.reducer;
