import { Box, Stack, Tooltip } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import AssetBox from '../asset/AssetBox';
import { Asset } from '../data/dataSlice';
import { blueprintSelector } from './BlueprintModal';

interface IStatType {
  key: 'atk' | 'def' | 'hp' | 'eva' | 'crit' | 'value';
  name: string;
  type: Asset['type'];
  format?: (value: string | number | undefined) => string | undefined;
}
const statTypes: IStatType[] = [
  { key: 'atk', name: 'Attack', type: 'statIndicator' },
  { key: 'def', name: 'Defense', type: 'statIndicator' },
  { key: 'hp', name: 'Health', type: 'statIndicator' },
  {
    key: 'eva',
    name: 'Evasion',
    type: 'statIndicator',
    format: (v) => `${Math.floor((v as number) * 100)}%`,
  },
  {
    key: 'crit',
    name: 'Critical Hit Chance',
    type: 'statIndicator',
    format: (v) => `${Math.floor((v as number) * 100)}%`,
  },
  {
    key: 'value',
    name: 'Gold',
    type: 'currency',
    format: (v) => v?.toLocaleString(),
  },
];

const BlueprintModalBlueprintStats = () => {
  const bp = useAppSelector((s) => blueprintSelector(s, 'blueprint'));

  if (!bp) return null;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box sx={{ textAlign: 'center' }}>Stats</Box>
      <Stack direction="row" spacing={3} justifyContent="center">
        {statTypes.map((s) => {
          if (!bp[s.key]) return null;
          return (
            <Tooltip arrow title={s.name} placement="top" key={s.key}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <AssetBox type={s.type} search={s.name} />
                <Box sx={{ width: 'auto' }}>
                  {s.format ? s.format(bp[s.key]) : bp[s.key]}
                </Box>
              </Box>
            </Tooltip>
          );
        })}
      </Stack>
    </Box>
  );
};

export default BlueprintModalBlueprintStats;
