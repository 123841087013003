import { Avatar, Box } from '@mui/material';
import AssetBadge from '../asset/AssetBadge';
import { BlueprintWithCollection } from '../data/dataSlice';
import { AirshipPower } from './CalculateCategory';
import CalculateCategoryItemEnchant from './CalculateCategoryItemEnchant';
import { useGetAssetBadgeProps } from './hooks';

interface Props {
  airshipPower: Omit<AirshipPower, 'power'>;
  elements: BlueprintWithCollection[];
  spirits: BlueprintWithCollection[];
}

interface CheckIsUniqueFn {
  (
    blueprint: BlueprintWithCollection,
    blueprints: BlueprintWithCollection[]
  ): boolean;
}
const checkIsUnique: CheckIsUniqueFn = (blueprint, blueprints) => {
  return !blueprints.some(
    (elm) =>
      elm.atk === blueprint.atk &&
      elm.def === blueprint.def &&
      elm.hp === blueprint.hp &&
      elm.tier === blueprint.tier &&
      elm.learned === blueprint.learned &&
      elm.name !== blueprint.name
  );
};

const CalculateCategoryEnchants: React.FC<Props> = ({
  airshipPower: { blueprint, element, spirit },
  elements,
  spirits,
}) => {
  const isElementUnique = !!element && checkIsUnique(element, elements);
  const elementBadgeProps = useGetAssetBadgeProps(element);
  const isSpiritUnique = !!spirit && checkIsUnique(spirit, spirits);
  const spiritBadgeProps = useGetAssetBadgeProps(spirit);

  return (
    <Box display="flex" gap={2} sx={{ width: '86px' }}>
      <AssetBadge {...elementBadgeProps}>
        <Avatar
          variant="square"
          sx={{
            '&': { backgroundColor: 'transparent' },
            width: '35px',
            height: '35px',
            overflow: 'visible',
          }}
        >
          <CalculateCategoryItemEnchant
            enchantType="Element"
            enchant={element}
            isUnique={isElementUnique}
            blueprint={blueprint}
          />
        </Avatar>
      </AssetBadge>
      <AssetBadge {...spiritBadgeProps}>
        <Avatar
          variant="square"
          sx={{
            '&': { backgroundColor: 'transparent' },
            width: '35px',
            height: '35px',
            overflow: 'visible',
          }}
        >
          <CalculateCategoryItemEnchant
            enchantType="Spirit"
            enchant={spirit}
            isUnique={isSpiritUnique}
            blueprint={blueprint}
          />
        </Avatar>
      </AssetBadge>
    </Box>
  );
};

export default CalculateCategoryEnchants;
