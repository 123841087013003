import { IconButton, InputAdornment } from '@mui/material';
import { TextField } from 'mui-rff';
import PlusOne from '@mui/icons-material/AddCircle';
import MinusOne from '@mui/icons-material/RemoveCircle';
import { TextFieldProps } from 'mui-rff';

interface IInputLevelProps extends TextFieldProps {
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  value: number;
  setValue: (value: number) => void;
}

const InputLevel: React.FC<IInputLevelProps> = ({
  minValue,
  maxValue,
  setValue,
  disabled = false,
  value,
  ...textFieldProps
}) => {
  let showValue = value;
  if (minValue && value < minValue) {
    showValue = minValue;
  }
  if (maxValue && value > maxValue) {
    showValue = maxValue;
  }

  const handleChange = (newValue: number) => {
    if (minValue && newValue < minValue) {
      newValue = minValue;
    }
    if (maxValue && newValue > maxValue) {
      newValue = maxValue;
    }
    setValue(newValue);
  };

  return (
    <TextField
      disabled={disabled}
      value={showValue}
      inputProps={{
        min: 1,
        max: 40,
      }}
      InputProps={{
        sx: {
          paddingX: 0,
          '& input': {
            textAlign: 'center',
          },
        },
        onChange: (e) => {
          handleChange(parseInt(e.target.value) || minValue || 0);
        },

        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              disabled={disabled || (minValue ? value <= minValue : false)}
              color="primary"
              onClick={() => {
                if (maxValue && value > maxValue) {
                  handleChange(maxValue - 1);
                } else {
                  handleChange(value - 1);
                }
              }}
            >
              <MinusOne />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={disabled || (maxValue ? value >= maxValue : false)}
              color="primary"
              onClick={() => {
                if (minValue && value < minValue) {
                  handleChange(minValue + 1);
                } else {
                  handleChange(value + 1);
                }
              }}
            >
              <PlusOne />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
};

export default InputLevel;
