import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Collection, IUserWorker, UserInitialState } from '../user/userSlice';
import { fetchDataAPI, importDataAPI } from './dataAPI';

export type Asset = {
  name: string;
  type:
    | 'chest'
    | 'component'
    | 'currency'
    | 'elementAffinity'
    | 'elementEnchanted'
    | 'filterType'
    | 'heroClass'
    | 'qualityIndicator'
    | 'resource'
    | 'spiritAffinity'
    | 'statIndicator'
    | 'tierFilter'
    | 'marketFilter'
    | 'miscIcons'
    | 'skillsLeader'
    | 'skillsInnate'
    | 'skillsHero'
    | 'characterPortraits'
    | 'blueprint';
  asset: string;
};

type IQualityTypes = 'Normal' | 'Flawless' | 'Superior' | 'Epic' | 'Legendary';

export type Blueprint = {
  id: EntityId;
  name: string;
  tier: number;
  class: string;
  type: string;
  atk?: number;
  def?: number;
  hp?: number;
  eva?: number;
  crit?: number;
  value?: number;
  valueMilestoneMultiplier?: number;
  airshipPower: number;
  wood?: number;
  iron?: number;
  leather?: number;
  herbs?: number;
  steel?: number;
  ironwood?: number;
  fabric?: number;
  oils?: number;
  mana?: number;
  gems?: number;
  essence?: number;
  component1?: string;
  componentQuality1?: IQualityTypes;
  amountNeeded1?: number;
  component2?: string;
  componentQuality2?: IQualityTypes;
  amountNeeded2?: number;
  craftingUpgrade1?: string;
  craftsNeeded1?: number;
  craftingUpgrade2?: string;
  craftsNeeded2?: number;
  craftingUpgrade3?: string;
  craftsNeeded3?: number;
  craftingUpgrade4?: string;
  craftsNeeded4?: number;
  craftingUpgrade5?: string;
  craftsNeeded5?: number;
  ascensionUpgrade1?: string;
  shardNeeded1?: number;
  ascensionUpgrade2?: string;
  shardNeeded2?: number;
  ascensionUpgrade3?: string;
  shardNeeded3?: number;
  airshipCategory?: 'Weapons' | 'Body Armor' | 'Misc Armor' | 'Accessories';
  availableFromChest?: string;
  availableAsAnAntique?: string;
  builtInElement?: string;
  builtInSpirit?: string;
  elementAffinity?: string;
  spiritAffinity?: string;
  unlockPrerequisite?: string;
  asset?: string;
  assetEnchantment?: string;
  requiredWorker1?: string;
  workerLevel1?: number;
  requiredWorker2?: string;
  workerLevel2?: number;
  requiredWorker3?: string;
  workerLevel3?: number;
  merchantXp: number;
};

export type MerchantLevel = {
  level: number;
  tierUnlock: number;
  xpNeeded: number;
  gemReward: number;
};

export interface IWorker {
  id: EntityId;
  name: string;
  worker: string;
  levelRequired: number;
  asset: string;
}

export type IWorkerWithUserData = IWorker & IUserWorker;

export interface IWorkerLevel {
  level: number;
  xp: number;
  speedBonus: number;
}

export type BlueprintType = {
  name: string;
  count: number;
  asset: string | null;
  class: string;
};

export type BlueprintClass = {
  name: string;
  count: number;
  asset: string | null;
};

export interface IImportData {
  merchant: UserInitialState['data'];
  collection: Collection[];
  workers: IUserWorker[];
}

export type BlueprintWithCollection = Blueprint & Collection;

export const blueprintAdapter = createEntityAdapter<Blueprint>();

export const blueprintSelectors = blueprintAdapter.getSelectors<RootState>(
  (state) => state.data.blueprints
);

export const workerAdapter = createEntityAdapter<IWorker>();
export const workerSelectors = workerAdapter.getSelectors<RootState>(
  (state) => state.data.workers
);

export const fetchData = createAsyncThunk('data/fetch', async () => {
  const response = await fetchDataAPI();
  console.group('fetchData');
  console.log(response);
  console.groupEnd();
  return response;
});

export const importDataAsync = createAsyncThunk(
  'data/import',
  async (data: IImportData) => {
    const response = await importDataAPI(data);
    return response;
  }
);

export type DataInitialState = {
  loadedTimestamp: number;
  appVersion: string;
  status: 'idle' | 'loading' | 'failed';
  blueprintTypes: BlueprintType[];
  blueprintClasses: BlueprintClass[];
  merchantLevels: MerchantLevel[];
  assets: Asset[];
  blueprints: ReturnType<typeof blueprintAdapter.getInitialState>;
  workers: ReturnType<typeof workerAdapter.getInitialState>;
  workerLevels: IWorkerLevel[];
};

const initialState: DataInitialState = {
  loadedTimestamp: 0,
  appVersion: '',
  status: 'idle',
  assets: [],
  blueprintTypes: [],
  blueprintClasses: [],
  blueprints: blueprintAdapter.getInitialState(),
  merchantLevels: [],
  workers: workerAdapter.getInitialState(),
  workerLevels: [],
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loadedTimestamp = Date.now();
        state.appVersion = process.env.REACT_APP_VERSION ?? '';
        state.blueprintTypes = action.payload.types;
        state.blueprintClasses = action.payload.classes;
        state.assets = action.payload.assets;
        state.merchantLevels = action.payload.merchantLevels;
        blueprintAdapter.setAll(state.blueprints, action.payload.blueprints);
        workerAdapter.setAll(state.workers, action.payload.workers);
        state.workerLevels = action.payload.workerLevels;
      })
      .addCase(fetchData.rejected, (state, error) => {
        console.log(error);
        state.status = 'failed';
      });
  },
});

export default dataSlice.reducer;
