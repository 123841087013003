import { Box, Button } from '@mui/material';
import { persistedState } from '../../app/store/migrations';
import DataMigration from './DataMigration';
import userReducer, { saveCollection } from '../user/userSlice';
import {
  useAppDispatch,
  useAppSelector,
  useKabamFetchData,
} from '../../app/hooks';

type UserState = ReturnType<typeof userReducer>;

const DataAdmin = () => {
  const dispatch = useAppDispatch();
  const kabamFetchData = useKabamFetchData();
  const loading = useAppSelector((state) => state.data.status === 'loading');

  const restoreCollection = () => {
    const storedCollection =
      persistedState<UserState['collection']>('persist:collection');
    console.log(storedCollection);

    if (storedCollection) {
      dispatch(saveCollection(storedCollection.entities));
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Button
          onClick={() => kabamFetchData()}
          disabled={loading}
          variant="contained"
        >
          Reload blueprints data
        </Button>
      </Box>
      <DataMigration />
      <Box mt={10}>
        <Button onClick={restoreCollection} variant="outlined">
          Restore my collection pre version 0.1.11
        </Button>
      </Box>
    </Box>
  );
};

export default DataAdmin;
