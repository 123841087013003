import { Box } from '@mui/system';
import { Checkbox, SxProps, Theme } from '@mui/material';

import { blueprintQualities } from '../collection/CollectionTableCollected';
import AssetBox from '../asset/AssetBox';
import { useAppSelector } from '../../app/hooks';
import { useSetBlueprintModalQuality } from '../core/hooks';

const initialSx: SxProps<Theme> = {
  width: '40px',
  height: '40px',
};

const size = {
  width: '40px',
  height: '40px',
};

const BlueprintModalSelectQuality = () => {
  const quality = useAppSelector((s) => s.core.blueprintModal.quality);
  const setQuality = useSetBlueprintModalQuality();
  return (
    <Box display="flex" mt={2} justifyContent="center">
      {blueprintQualities.map((q) => (
        <Checkbox
          key={q.name}
          checked={quality === q.name}
          color={q.color}
          onChange={() => setQuality(q.name)}
          checkedIcon={
            <AssetBox {...size} src={q.asset} sx={initialSx} shadow />
          }
          icon={
            <AssetBox
              {...size}
              src={q.asset}
              sx={{
                ...initialSx,
                ...{ opacity: 0.25, grayscale: 0.55 },
              }}
              shadow
            />
          }
        />
      ))}
    </Box>
  );
};

export default BlueprintModalSelectQuality;
