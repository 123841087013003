import { EntityId } from '@reduxjs/toolkit';
import {
  blueprintSelectors,
  BlueprintWithCollection,
} from '../../features/data/dataSlice';
import {
  collectionSelectors,
  getNewCollection,
} from '../../features/user/userSlice';
import { useAppSelector } from './storeHooks';

export const useGetBlueprintWithCollection = (id: EntityId | undefined) => {
  return useAppSelector((state) => {
    const blueprint = id ? blueprintSelectors.selectById(state, id) : undefined;
    const collection = id
      ? collectionSelectors.selectById(state, id)
      : undefined;
    if (!blueprint || !id) return undefined;
    return {
      ...blueprint,
      ...(collection ?? getNewCollection(id)),
    } as BlueprintWithCollection;
  });
};
