import React from 'react';
import { Switch } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';

import { useGetCollectionProp } from '../../app/hooks';
import { useHandleToggleProp } from './hooks';

type Props = {
  id: EntityId;
};

const CollectionTableCellMastered: React.FC<Props> = ({ id }) => {
  const learned = useGetCollectionProp(id, 'learned') || false;
  const mastered = useGetCollectionProp(id, 'mastered') || false;
  const handleToggleLearned = useHandleToggleProp(id, 'mastered');

  return (
    <Switch
      disabled={!learned}
      checked={mastered}
      color="success"
      onChange={handleToggleLearned}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

export default CollectionTableCellMastered;

export const MemoizedCollectionTableCellMastered = React.memo(
  CollectionTableCellMastered
);
