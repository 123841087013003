import { Box } from '@mui/material';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { useAppSelector, useGetWorkersWithUserData } from '../../app/hooks';
import AssetBox from '../asset/AssetBox';
// import { useOpenWorkerModal } from '../core/hooks';
import { IWorkerWithUserData } from '../data/dataSlice';
import WorkerTableCellUnlocked from './WorkerTableCellUnlocked';
import WorkerTableCellLevel from './WorkerTableCellLevel';
import { isEqual } from 'lodash';
import { MRTFixedCellSize } from '../core/coreHelpers';

const WorkerTable = () => {
  const workers = useGetWorkersWithUserData();
  const workerLevels = useAppSelector(
    (state) => state.data.workerLevels,
    isEqual
  );
  const merchantLevel = useAppSelector(
    (state) => state.user.data.level,
    isEqual
  );
  // const openModal = useOpenWorkerModal();

  const columns = useMemo<MRT_ColumnDef<IWorkerWithUserData>[]>(
    () => [
      {
        id: 'icon',
        header: '',
        Header: false,
        accessorKey: 'asset',
        // Cell: ({ row }) => <AssetBox src={row.original.asset} />,
        Cell: ({ row }) => (
          <Box
            width="35px"
            height="35px"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              mt: 1,
              border: 2,
              borderRadius: '50%',
              backgroundColor: 'red',
              p: 0,
            }}
            position="relative"
          >
            <AssetBox src={row.original.asset} />
          </Box>
        ),
        enableColumnFilter: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        size: 50,
        ...MRTFixedCellSize(),
      },
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Worker',
        accessorKey: 'worker',
      },
      {
        header: 'Unlocked',
        Cell: ({ row }) => {
          const disabled = merchantLevel < row.original.levelRequired;
          return (
            <WorkerTableCellUnlocked
              worker={row.original}
              disabled={disabled}
            />
          );
        },
        accessorFn: (worker) => (worker.unlocked ? 'Yes' : 'No'),
      },
      {
        header: 'Level',
        Cell: ({ row }) => {
          const disabled = merchantLevel < row.original.levelRequired;
          return (
            <WorkerTableCellLevel worker={row.original} disabled={disabled} />
          );
        },
        accessorFn: (worker) => worker.level,
      },
      {
        header: 'Speed bonus',
        accessorFn: (worker) => {
          const level = workerLevels.find((l) => l.level === worker.level);
          if (!level) return 0;
          return level.speedBonus;
        },
        Cell: ({ cell }) => {
          return <div>{cell.getValue<number>() || 0}%</div>;
        },
      },
    ],
    [merchantLevel, workerLevels]
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={workers}
      layoutMode="grid"
      initialState={{
        density: 'compact',
      }}
      enablePagination={false}
      enablePinning={false}
      enableColumnDragging={false}
      enableColumnOrdering={false}
      enableColumnFilters={false}
      enableColumnResizing={false}
      enableDensityToggle={false}
      enableTopToolbar={false}
      //   enableToolbarInternalActions={false}
      enableBottomToolbar={false}
      enableHiding={false}
      // muiTableBodyRowProps={({ row }) => ({
      //   onClick: () => {
      //     openModal(row.original.id);
      //   },
      //   sx: { cursor: 'pointer' },
      // })}
    />
  );
};

export default WorkerTable;
