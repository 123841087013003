import { useGetMaxMarketTier } from '../../app/hooks/useGetMaxMarketTier';
import Slider, { SliderProps } from '../../app/Slider';

interface Props extends Omit<SliderProps, 'step' | 'min' | 'max' | 'marks'> {
  disableMarkLabels?: boolean;
}

/**
 *
 * Demos:
 *
 * - [Slider](https://mui.com/material-ui/react-slider/)
 *
 * API:
 *
 * - [Slider API](https://mui.com/material-ui/api/slider/)
 * - inherits [SliderUnstyled API](https://mui.com/base/api/slider-unstyled/)
 */
const TierSlider: React.FC<Props> = ({
  name,
  disableMarkLabels = false,
  ...restSlider
}) => {
  const min = 1;
  const max = useGetMaxMarketTier();
  const marks = [];
  for (let i = min; i <= max; i++) {
    if (disableMarkLabels) {
      marks.push({ value: i });
    } else {
      marks.push({ value: i, label: i.toString() });
    }
  }

  return (
    <Slider
      name={name}
      valueLabelDisplay={disableMarkLabels ? 'auto' : 'off'}
      step={1}
      min={1}
      max={max}
      marks={marks}
      sx={{
        ml: 1,
        width: 'calc(100% - 10px)',
      }}
      {...restSlider}
    />
  );
};

export default TierSlider;
