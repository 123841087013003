import {
  Action,
  // autoBatchEnhancer,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  createMigrate,
  PersistConfig,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import coreReducer from '../../features/core/coreSlice';
import dataReducer from '../../features/data/dataSlice';
import userReducer from '../../features/user/userSlice';
import { dataMigrations, userMigrations } from './migrations';

const persistRootConfig = {
  key: 'root',
  storage,
  blacklist: ['core', 'data', 'user'],
};

const userPersistConfig: PersistConfig<ReturnType<typeof userReducer>> = {
  key: 'user',
  storage,
  version: 1,
  migrate: createMigrate(userMigrations),
};

const dataPersistConfig: PersistConfig<ReturnType<typeof dataReducer>> = {
  key: 'data',
  storage,
  version: 1,
  migrate: createMigrate(dataMigrations),
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  core: coreReducer,
  data: persistReducer(dataPersistConfig, dataReducer),
  user: persistReducer(userPersistConfig, userReducer),
});

const persistedRootReducer = persistReducer(persistRootConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          'persist/PURGE',
          'persist/FLUSH',
          'persist/PAUSE',
          'persist/REGISTER',
        ],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
