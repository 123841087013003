import { Box, Button, Stack, TextField as MuiTextField } from '@mui/material';
import { FormApi, FORM_ERROR } from 'final-form';
import { isBoolean, isNumber, keyBy, mergeWith } from 'lodash';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { useAppSelector, useImportData } from '../../app/hooks';
import { collectionSelectors, userWorkerSelectors } from '../user/userSlice';

interface FormValues {
  data: string;
}

const mergeWithCustomizer = (objVal: any, srcVal: any) => {
  if (isBoolean(objVal)) return objVal || srcVal;
  if (isNumber(objVal)) return Math.max(objVal, srcVal);
};

const DataMigration = () => {
  const merchant = useAppSelector((state) => state.user.data);
  const importData = useImportData();
  const collection = useAppSelector((state) =>
    collectionSelectors.selectAll(state)
  );
  const workers = useAppSelector((state) =>
    userWorkerSelectors.selectAll(state)
  );

  const onSubmit = (formValues: FormValues, form: FormApi<FormValues>) => {
    const { data } = formValues;
    try {
      const sendData = JSON.parse(data);
      if (sendData.collection) {
        sendData.collection = mergeWith(
          {},
          keyBy(collection, 'id'),
          keyBy(sendData.collection, 'id'),
          mergeWithCustomizer
        );
      }
      if (sendData.workers) {
        sendData.workers = mergeWith(
          {},
          keyBy(workers, 'id'),
          keyBy(sendData.workers, 'id'),
          mergeWithCustomizer
        );
      }
      if (sendData.merchant) {
        sendData.merchant = mergeWith(
          {},
          merchant,
          sendData.merchant,
          mergeWithCustomizer
        );
      }

      return importData(sendData);
    } catch (error) {
      return { [FORM_ERROR]: 'Invalid data', data: 'Invalid data' };
    }
  };

  const data = {
    merchant,
    collection,
    workers,
  };

  return (
    <Box>
      <Box component="h3">Data Transfer</Box>
      <Stack gap={4}>
        <Box>
          <MuiTextField
            fullWidth
            label="Export"
            multiline
            rows={10}
            sx={{ maxWidth: '750px' }}
            value={data ? JSON.stringify(data) : ''}
          />
        </Box>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  helperText={
                    <Box component="span" display="flex" flexDirection="column">
                      <Box component="span">
                        Imported data is merged with your current data
                      </Box>
                      <Box component="span">
                        Collection are merged preserving any true values for
                        learned, mastered and each of the 5 qualities
                      </Box>
                      <Box component="span">
                        Worker and merchant levels use the max value found in
                        the two datasets
                      </Box>
                    </Box>
                  }
                  name="data"
                  fullWidth
                  label="Paste data here to import"
                  multiline
                  rows={10}
                  sx={{ maxWidth: '750px' }}
                />
                <Box>
                  <Button type="submit" variant="contained">
                    Import
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        />
      </Stack>
    </Box>
  );
};

export default DataMigration;
