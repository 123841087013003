import { createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import {
  CollectedQualityTypes,
  ISettingsAirshipCategories,
} from '../user/userSlice';

export interface CoreInitialState {
  airshipSettingsModal: {
    open: boolean;
    tab: ISettingsAirshipCategories;
  };
  blueprintModal: {
    open: boolean;
    tab: 'blueprint' | 'airship' | 'collection';
    blueprintId?: EntityId;
    quality: CollectedQualityTypes;
    elementId?: EntityId;
    spiritId?: EntityId;
  };
  kingBaitSettingsModal: {
    open: boolean;
  };
  workerModal: {
    open: boolean;
    workerId?: EntityId;
  };
}

export interface OpenBlueprintModalPayload {
  blueprintId: CoreInitialState['blueprintModal']['blueprintId'];
  quality?: CoreInitialState['blueprintModal']['quality'];
  elementId?: CoreInitialState['blueprintModal']['elementId'];
  spiritId?: CoreInitialState['blueprintModal']['spiritId'];
  tab?: CoreInitialState['blueprintModal']['tab'];
}

export interface OpenWorkerModalPayload {
  workerId: CoreInitialState['workerModal']['workerId'];
}

const initialState: CoreInitialState = {
  airshipSettingsModal: {
    open: false,
    tab: 'weapons',
  },
  blueprintModal: {
    open: false,
    tab: 'blueprint',
    quality: 'normal',
  },
  kingBaitSettingsModal: {
    open: false,
  },
  workerModal: {
    open: false,
  },
};

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    openAirshipSettingsModal: (
      state,
      action: PayloadAction<ISettingsAirshipCategories | undefined>
    ) => {
      state.airshipSettingsModal.open = true;
      if (action.payload) {
        state.airshipSettingsModal.tab = action.payload;
      }
    },
    closeAirshipSettingsModal: (state) => {
      state.airshipSettingsModal.open = false;
    },
    openBlueprintModal: (
      state,
      action: PayloadAction<OpenBlueprintModalPayload>
    ) => {
      const {
        blueprintId,
        quality,
        elementId,
        spiritId,
        tab = initialState.blueprintModal.tab,
      } = action.payload;
      state.blueprintModal.open = true;
      state.blueprintModal.tab = tab;
      state.blueprintModal.blueprintId = blueprintId;
      if (quality) {
        state.blueprintModal.quality = quality;
      }
      state.blueprintModal.elementId = elementId;
      state.blueprintModal.spiritId = spiritId;
    },
    setBlueprintModalTab: (
      state,
      action: PayloadAction<CoreInitialState['blueprintModal']['tab']>
    ) => {
      state.blueprintModal.tab = action.payload;
    },
    setBlueprintModalQuality: (
      state,
      action: PayloadAction<CoreInitialState['blueprintModal']['quality']>
    ) => {
      state.blueprintModal.quality = action.payload;
    },
    setBlueprintModalEntity: (
      state,
      action: PayloadAction<{
        type: 'blueprint' | 'element' | 'spirit';
        entityId: EntityId | undefined;
      }>
    ) => {
      const { type, entityId } = action.payload;
      state.blueprintModal[`${type}Id`] = entityId;
    },
    closeBlueprintModal: (state) => {
      state.blueprintModal.open = false;
      state.blueprintModal.quality = initialState.blueprintModal.quality;
    },
    openKingBaitSettingsModal: (state) => {
      state.kingBaitSettingsModal.open = true;
    },
    closeKingBaitSettingsModal: (state) => {
      state.kingBaitSettingsModal.open = false;
    },
    openWorkerModal: (state, action: PayloadAction<OpenWorkerModalPayload>) => {
      state.workerModal.open = true;
      state.workerModal.workerId = action.payload.workerId;
    },
    closeWorkerModal: (state) => {
      state.workerModal.open = false;
    },
  },
});

export const {
  openAirshipSettingsModal,
  closeAirshipSettingsModal,
  openBlueprintModal,
  closeBlueprintModal,
  setBlueprintModalTab,
  setBlueprintModalQuality,
  setBlueprintModalEntity,
  openKingBaitSettingsModal,
  closeKingBaitSettingsModal,
  openWorkerModal,
  closeWorkerModal,
} = coreSlice.actions;
export default coreSlice.reducer;
