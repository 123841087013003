import { Blueprint, blueprintAdapter } from '../../features/data/dataSlice';

const useSelectBlueprintId = () => {
  return (bp?: Blueprint) => {
    if (!bp) return undefined;
    return blueprintAdapter.selectId(bp);
  };
};

export { useSelectBlueprintId };
