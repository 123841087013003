import { EntityId } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../app/hooks';
import { saveUserWorkerLevel, toggleWorkerUnlocked } from '../user/userSlice';

export const useToggleUnlocked = (id: EntityId) => {
  const dispatch = useAppDispatch();
  return () => dispatch(toggleWorkerUnlocked(id));
};

export const useSetUserWorkerLevel = (id: EntityId) => {
  const dispatch = useAppDispatch();
  return (level: number) => dispatch(saveUserWorkerLevel(id, level));
};
