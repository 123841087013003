import { Box, Menu, MenuItem } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import { capitalize } from 'lodash';

import { useGetBlueprintsWithCollection } from '../../app/hooks';
import AssetBox from '../asset/AssetBox';
import { useSetBlueprintModalEntity } from '../core/hooks';
import { blueprintAdapter } from '../data/dataSlice';
import BlueprintModalSelectEnchantmentPopoverMenuItem from './BlueprintModalSelectEnchantmentPopoverMenuItem';
import { useGetSelectedBlueprintWithCollection } from './hooks';

type Props = {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  enchantmentType: 'element' | 'spirit';
  selected: EntityId | undefined;
};

const BlueprintModalSelectEnchantmentPopover: React.FC<Props> = ({
  anchorEl,
  setAnchorEl,
  enchantmentType,
  selected,
}) => {
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const blueprint = useGetSelectedBlueprintWithCollection('blueprint');
  const enchantments = useGetBlueprintsWithCollection(
    (bp) => bp.type === capitalize(enchantmentType)
  );
  const setEnchantment = useSetBlueprintModalEntity();
  const handleSelectEnhantment = (id: EntityId | undefined) => {
    console.log('id', id);
    setEnchantment(enchantmentType, id);
    handleClose();
  };
  const removeSelectedEnchantment = () => {
    setEnchantment(enchantmentType, undefined);
    handleClose();
  };

  const affinities = enchantments.reduce((acc, bp) => {
    const affinity = bp[`${enchantmentType}Affinity`] as string;
    if (!acc.includes(affinity)) {
      acc.push(affinity);
    }
    return acc;
  }, [] as string[]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: enchantmentType === 'element' ? 'left' : 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: enchantmentType === 'element' ? 'right' : 'left',
      }}
    >
      {enchantmentType === 'element' ? (
        <Box display="flex" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Box sx={{ width: '100%' }}>
            <MenuItem onClick={removeSelectedEnchantment}>
              <Box display="flex" gap={1} sx={{ ml: '56.67px ' }}>
                <AssetBox search="None" width="24px" height="24px" />
                <Box>None</Box>
              </Box>
            </MenuItem>
          </Box>
        </Box>
      ) : (
        <MenuItem sx={{ width: '100%' }} onClick={removeSelectedEnchantment}>
          <Box display="flex" gap={1}>
            <AssetBox search="None" width="24px" height="24px" />
            <Box>None</Box>
          </Box>
        </MenuItem>
      )}
      {enchantmentType === 'element'
        ? affinities.map((affinity, index) => {
            return (
              <Box
                key={affinity}
                display="flex"
                sx={
                  index === affinities.length - 1
                    ? {}
                    : {
                        borderBottom: 1,
                        borderColor: 'divider',
                      }
                }
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRight: 1,
                    borderColor: 'divider',
                    p: 1,
                  }}
                >
                  <AssetBox
                    search={affinity}
                    type="elementAffinity"
                    shadow={
                      blueprint?.elementAffinity === affinity
                        ? 'affinity'
                        : false
                    }
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  {enchantments
                    .filter(
                      (bp) => bp[`${enchantmentType}Affinity`] === affinity
                    )
                    .map((bp) => {
                      const id = blueprintAdapter.selectId(bp);
                      return (
                        <BlueprintModalSelectEnchantmentPopoverMenuItem
                          key={id}
                          id={id}
                          enchant={bp}
                          selectedId={selected}
                          onSelect={handleSelectEnhantment}
                        />
                      );
                    })}
                </Box>
              </Box>
            );
          })
        : enchantments.map((bp) => {
            const id = blueprintAdapter.selectId(bp);
            return (
              <BlueprintModalSelectEnchantmentPopoverMenuItem
                key={id}
                id={id}
                enchant={bp}
                selectedId={selected}
                onSelect={handleSelectEnhantment}
              />
            );
          })}
    </Menu>
  );
};

export default BlueprintModalSelectEnchantmentPopover;
