import { useAppSelector, useGetBlueprintWithCollection } from '../../app/hooks';
import { BlueprintWithCollection } from '../data/dataSlice';

interface UseGetSelectedBlueprintWithCollectionFn {
  (type: 'blueprint' | 'element' | 'spirit'):
    | BlueprintWithCollection
    | undefined;
}

export const useGetSelectedBlueprintWithCollection: UseGetSelectedBlueprintWithCollectionFn =
  (type) => {
    const id = useAppSelector(
      (state) => state.core.blueprintModal[`${type}Id`]
    );
    const blueprint = useGetBlueprintWithCollection(id);

    return blueprint;
  };

/*
import { useAppDispatch } from '../../app/hooks';
import { fetchBlueprints } from './blueprintSlice';

export const useFetchBlueprints = () => {
  const dispatch = useAppDispatch();

  return async () => {
    try {
      await dispatch(fetchBlueprints());
    } catch (err) {
      console.log(err);
    }
  };
};

// */
