import { Box } from '@mui/material';
import { map } from 'lodash';
import versionLog from '../../version.json';

const Version = () => {
  return (
    <>
      <Box component="h2" mb={2} mt={0}>
        Changelog
      </Box>
      {map(versionLog, (data, version) => {
        const date = new Date(data.date);
        return (
          <Box key={version} mb={2}>
            <Box display="flex" gap={1}>
              <Box component="h4" m={0}>
                {version}
              </Box>
              <Box>{date.toLocaleDateString()}</Box>
            </Box>
            {data.added.length > 0
              ? data.added.map((added, i) => {
                  return (
                    <Box key={i} display="flex" gap={1}>
                      <Box sx={{ minWidth: '75px' }}>[added]</Box>
                      <Box>{added}</Box>
                    </Box>
                  );
                })
              : ''}
            {data.updated.length > 0
              ? data.updated.map((updated, i) => {
                  return (
                    <Box key={i} display="flex" gap={1}>
                      <Box sx={{ minWidth: '75px' }}>[updated]</Box>
                      <Box>{updated}</Box>
                    </Box>
                  );
                })
              : ''}
          </Box>
        );
      })}
    </>
  );
};

export default Version;
