import { EntityId } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import {
  collectionSelectors,
  getNewCollection,
} from '../../features/user/userSlice';
import { useAppSelector } from '.';

const useGetCollection = (id: EntityId) => {
  const collection = useAppSelector(
    (state) => (id ? collectionSelectors.selectById(state, id) : undefined),
    isEqual
  );
  return collection || getNewCollection(id);
};

export { useGetCollection };
