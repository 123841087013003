import { Box, Stack, Tooltip } from '@mui/material';
import { useAppSelector, useGetWorkersWithUserData } from '../../app/hooks';
import AssetBox from '../asset/AssetBox';
import { blueprintSelector } from './BlueprintModal';

const BlueprintModalBlueprintWorkers = () => {
  const bp = useAppSelector((s) => blueprintSelector(s, 'blueprint'));
  const workers = useGetWorkersWithUserData();

  if (!bp) return null;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box sx={{ textAlign: 'center' }}>Workers</Box>
      <Stack direction="row" spacing={3} justifyContent="center">
        {[1, 2, 3].map((i) => {
          const req = `requiredWorker${i}` as
            | 'requiredWorker1'
            | 'requiredWorker2'
            | 'requiredWorker3';
          const level = `workerLevel${i}` as
            | 'workerLevel1'
            | 'workerLevel2'
            | 'workerLevel3';
          const worker = workers.find((w) => w.worker === bp[req]);
          if (!worker) return null;
          const bpLevel = bp[level];
          const tooLow = bpLevel && bpLevel > worker.level;
          return (
            <Tooltip
              arrow
              title={`${worker.name} lvl ${bpLevel}`}
              placement="top"
              key={i}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  width="37px"
                  height="37px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{
                    mt: 1,
                    border: 2,
                    borderRadius: '50%',
                    backgroundColor: tooLow ? 'error.main' : 'success.main',
                    p: 0,
                  }}
                  position="relative"
                >
                  <AssetBox src={worker.asset} />
                </Box>
                <Box
                  sx={{
                    ...(tooLow ? { color: 'error.main' } : {}),
                  }}
                >
                  Lvl {bp[level]}
                </Box>
              </Box>
            </Tooltip>
          );
        })}
      </Stack>
    </Box>
  );
};

export default BlueprintModalBlueprintWorkers;
