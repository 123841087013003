import { isEqual } from 'lodash';

import {
  Blueprint,
  blueprintAdapter,
  blueprintSelectors,
} from '../../features/data/dataSlice';
import { useAppSelector } from '.';
import { collectionSelectors } from '../../features/user/userSlice';

type Props = keyof Blueprint;

export type PropSearch<T extends Props> = {
  prop: T;
  search: number | string | boolean;
};

interface UseGetBlueprintSearch {
  (
    propSearches: PropSearch<keyof Blueprint>[],
    learned?: boolean,
    operator?: 'AND' | 'OR'
  ): Blueprint[];
}

const useGetBlueprintsSearch: UseGetBlueprintSearch = (
  propSearches,
  learned,
  operator = 'AND'
) => {
  const learnedIds = useAppSelector(
    (state) =>
      collectionSelectors
        .selectAll(state)
        .filter((c) => c.learned === true)
        .map((c) => c.id),
    isEqual
  );

  const blueprints = useAppSelector((state) => {
    const allBlueprints = blueprintSelectors.selectAll(state);
    let blueprints;
    if (operator === 'AND') {
      blueprints = allBlueprints.filter((bp) =>
        propSearches.every((ps) => bp[ps.prop] === ps.search)
      );
    } else {
      blueprints = allBlueprints.filter((bp) =>
        propSearches.some((ps) => bp[ps.prop] === ps.search)
      );
    }
    if (learned !== undefined) {
      blueprints = blueprints.filter((bp) => {
        const id = blueprintAdapter.selectId(bp);
        return learned === learnedIds.includes(id);
      });
    }
    return blueprints;
  }, isEqual);
  return blueprints;
};

export { useGetBlueprintsSearch };
