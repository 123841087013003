import { EntityId } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import {
  getNewUserWorker,
  userWorkerSelectors,
} from '../../features/user/userSlice';
import { useAppSelector } from './storeHooks';

export const useGetUserWorker = (id: EntityId) => {
  const userWorker = useAppSelector(
    (state) =>
      userWorkerSelectors.selectById(state, id) ?? getNewUserWorker(id),
    isEqual
  );

  return userWorker;
};
