import { IWorker } from '../data/dataSlice';
import { useRef, useState } from 'react';
import { useAppSelector, useGetUserWorker } from '../../app/hooks';
import InputLevel from './InputLevel';
import { debounce } from 'lodash';
import { useSetUserWorkerLevel } from './hooks';

interface IWorkerTableCellLevelProps {
  worker: IWorker;
  disabled?: boolean;
}

const WorkerTableCellLevel: React.FC<IWorkerTableCellLevelProps> = ({
  worker,
  disabled = false,
}) => {
  const userWorker = useGetUserWorker(worker.id);
  const setUserWorkerLevel = useSetUserWorkerLevel(userWorker.id);
  // find max value in state.data.workerLevels
  const maxValue = useAppSelector((state) =>
    state.data.workerLevels.reduce(
      (max, item) => (item.level > max ? item.level : max),
      0
    )
  );
  const minValue = useAppSelector((state) =>
    state.data.workerLevels.reduce(
      (min, item) => (item.level < min ? item.level : min),
      99999
    )
  );
  const [value, setValue] = useState(userWorker.level);

  const debouncedSetValue = useRef(
    debounce((val: number) => {
      setUserWorkerLevel(val);
    }, 1000)
  ).current;

  const handleSetValue = (val: number) => {
    setValue(val);
    debouncedSetValue(val);
  };

  const isDisabled = disabled || !userWorker.unlocked;

  return (
    <InputLevel
      value={value}
      setValue={handleSetValue}
      disabled={isDisabled}
      name="workerLevel"
      minValue={minValue}
      maxValue={maxValue}
      sx={{ width: '120px' }}
      size="small"
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};

export default WorkerTableCellLevel;
