import { NonUndefined } from 'tsdef';
import { useBuildDefaultTypeFiltersSettings } from '.';
import { airshipCategories } from '../../features/data/dataHelpers';
import { UserInitialState } from '../../features/user/userSlice';

const airshipSettingsFormCategories = [
  ...airshipCategories,
  { key: 'all' },
] as typeof airshipCategories;

export const useBuildAirshipDefaultSettings = () => {
  const types = useBuildDefaultTypeFiltersSettings();
  return () => {
    const settings = airshipSettingsFormCategories.reduce((acc, curr) => {
      acc[curr.key] = types;
      return acc;
    }, {} as any);

    const initialValues: NonUndefined<UserInitialState['airship']> = {
      shareSettings: true,
      settings,
    };

    return initialValues as NonUndefined<UserInitialState['airship']>;
  };
};
