import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box,
  CssBaseline,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Outlet } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import useGetTheme from './theme';
import { globalDebug } from './app/globalDebug';
import AppDrawer from './AppDrawer';
import AppMain from './app/AppMain';
import AirshipSettingsModal from './features/airship/AirshipSettingsModal';
import BlueprintModal from './features/blueprint/BlueprintModal';
import WorkerModal from './features/merchant/WorkerModal';
import KingBaitSettingsModal from './features/kingBait/KingBaitSettingsModal';
import SetThemeMode from './features/core/SetThemeMode';

const drawerwidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerwidth}px)`,
    marginLeft: `${drawerwidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function App() {
  useEffect(() => {
    (process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'STAGING') &&
      globalDebug(false);
  }, []);
  const [open, setOpen] = useState(false);

  const theme = useGetTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }} id="test?">
        <CssBaseline enableColorScheme />
        <AppBar
          position="fixed"
          // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <Toolbar>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                  edge="start"
                  // sx={{ mr: 2 }}
                  sx={{ mr: 2, ...(open && { display: 'none' }) }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  Shop Titans Companion {process.env.REACT_APP_VERSION}
                </Typography>
              </Box>
              <SetThemeMode />
            </Box>
          </Toolbar>
        </AppBar>
        <AppDrawer
          open={open}
          drawerwidth={drawerwidth}
          handleDrawerClose={handleDrawerClose}
        />
        <AppMain open={open} drawerwidth={drawerwidth}>
          <Toolbar />
          <Outlet />
        </AppMain>
      </Box>
      <AirshipSettingsModal />
      <BlueprintModal />
      <KingBaitSettingsModal />
      <WorkerModal />
    </ThemeProvider>
  );
}

export default App;
