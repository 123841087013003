import { Box, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AssetBox from '../asset/AssetBox';
import { AirshipCategory } from '../data/dataHelpers';
import { useOpenAirshipSettingsModal } from '../core/hooks';

interface Props {
  airshipCategory: AirshipCategory;
}

const CalculateCategoryTopToolbar: React.FC<Props> = ({ airshipCategory }) => {
  const open = useOpenAirshipSettingsModal();

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box
        component="h3"
        mb={0}
        mt={0}
        display="flex"
        gap={1}
        alignItems="center"
      >
        <AssetBox
          width="24px"
          height="24px"
          type="filterType"
          search={airshipCategory.assetName}
        />
        <Box>{airshipCategory.name}</Box>
      </Box>
      <IconButton onClick={() => open(airshipCategory.key)}>
        <SettingsIcon />
      </IconButton>
    </Box>
  );
};

export default CalculateCategoryTopToolbar;
