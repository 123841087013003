import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { forwardRef, ReactNode, useMemo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

// https://dragoshmocrii.com/using-react-router-navlink-with-a-mui-listitembutton-typescript/

type Props = React.PropsWithChildren<{
  to: string;
  text: string;
  icon: ReactNode;
}>;

const AppMenuLink: React.FC<Props> = ({ to, text, icon }) => {
  type MyNavLinkProps = Omit<NavLinkProps, 'to'>;
  const MyNavLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, MyNavLinkProps>((navLinkProps, ref) => {
        const { className: previousClasses, ...rest } = navLinkProps;
        const elementClasses = previousClasses?.toString() ?? '';
        return (
          <NavLink
            {...rest}
            ref={ref}
            to={to}
            end
            className={({ isActive }) =>
              isActive ? elementClasses + ' Mui-selected' : elementClasses
            }
          />
        );
      }),
    [to]
  );

  return (
    <ListItem disablePadding>
      <ListItemButton component={MyNavLink}>
        <ListItemIcon
          sx={{
            '.Mui-selected > &': {
              color: (theme) => theme.palette.primary.main,
            },
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

export default AppMenuLink;
