import {
  Box,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

import AppMenuLink from './AppMenuLink';
import AssetBox from './features/asset/AssetBox';
import { useAppSelector, useKabamFetchData } from './app/hooks';
import { useEffect, useRef } from 'react';

const iconSize = '32px';

const AppMenu = () => {
  const kabamFetchData = useKabamFetchData();
  const appVersion = useAppSelector((state) => state.data.appVersion);
  const lastLoaded = useAppSelector((state) => state.data.loadedTimestamp);

  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    calledOnce.current = true;
    if (process.env.REACT_APP_VERSION !== appVersion) {
      kabamFetchData();
    }
  }, [appVersion, kabamFetchData]);

  useEffect(() => {
    // set timer to check every 5 min if its been more than an hour since data lastLoaded and if so fetch data
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const diff = now - lastLoaded;
      if (diff > 3600000) {
        kabamFetchData();
      }
    }, 300000);

    return () => clearInterval(interval);
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="calc(100vh - 80px)"
    >
      <List>
        <AppMenuLink
          to="/"
          text="Dragon Invasion"
          icon={
            <AssetBox
              width={iconSize}
              height={iconSize}
              type="statIndicator"
              search="Airship Power"
            />
          }
        />
        <AppMenuLink
          to="/kingbait"
          text="King Bait"
          icon={
            <AssetBox
              width={iconSize}
              height={iconSize}
              type="characterPortraits"
              search="Reinhold"
            />
          }
        />
        {/* <AppMenuLink
          to="/kingscaprice"
          text="King's Caprice"
          icon={
            <AssetBox
              width={iconSize}
              height={iconSize}
              type="statIndicator"
              search="Favor"
            />
          }
        /> */}
        <AppMenuLink
          to="/collection"
          text="Collection"
          icon={
            <AssetBox
              width={iconSize}
              height={iconSize}
              type="miscIcons"
              search="Collection Book"
            />
          }
        />
        <AppMenuLink
          to="/precraft"
          text="Precraft"
          icon={
            <AssetBox
              width={iconSize}
              height={iconSize}
              type="miscIcons"
              search="Craft"
            />
          }
        />
        <AppMenuLink
          to="/merchant"
          text="Merchant"
          icon={
            <AssetBox
              width={iconSize}
              height={iconSize}
              src="/assets/Misc Icons/icon_global_player.png"
            />
          }
        />
        <AppMenuLink
          to="/changelog"
          text="Changelog"
          icon={<AppsOutageIcon sx={{ width: iconSize, height: iconSize }} />}
        />
        <AppMenuLink
          to="/data"
          text="Data Admin"
          icon={
            <DriveFileMoveIcon sx={{ width: iconSize, height: iconSize }} />
          }
        />
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            href="https://discord.gg/FYK6GT5J7q"
            target="_blank"
          >
            <ListItemIcon
              sx={{
                '.Mui-selected > &': {
                  color: (theme) => theme.palette.primary.main,
                },
              }}
            >
              <svg
                width="40px"
                height="40px"
                viewBox="0 0 512 512"
                fill="#5865f2"
                aria-label="Discord"
                role="img"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m386 137c-24-11-49.5-19-76.3-23.7c-.5 0-1 0-1.2.6c-3.3 5.9-7 13.5-9.5 19.5c-29-4.3-57.5-4.3-85.7 0c-2.6-6.2-6.3-13.7-10-19.5c-.3-.4-.7-.7-1.2-.6c-23 4.6-52.4 13-76 23.7c-.2 0-.4.2-.5.4c-49 73-62 143-55 213c0 .3.2.7.5 1c32 23.6 63 38 93.6 47.3c.5 0 1 0 1.3-.4c7.2-9.8 13.6-20.2 19.2-31.2c.3-.6 0-1.4-.7-1.6c-10-4-20-8.6-29.3-14c-.7-.4-.8-1.5 0-2c2-1.5 4-3 5.8-4.5c.3-.3.8-.3 1.2-.2c61.4 28 128 28 188 0c.4-.2.9-.1 1.2.1c1.9 1.6 3.8 3.1 5.8 4.6c.7.5.6 1.6 0 2c-9.3 5.5-19 10-29.3 14c-.7.3-1 1-.6 1.7c5.6 11 12.1 21.3 19 31c.3.4.8.6 1.3.4c30.6-9.5 61.7-23.8 93.8-47.3c.3-.2.5-.5.5-1c7.8-80.9-13.1-151-55.4-213c0-.2-.3-.4-.5-.4Zm-192 171c-19 0-34-17-34-38c0-21 15-38 34-38c19 0 34 17 34 38c0 21-15 38-34 38zm125 0c-19 0-34-17-34-38c0-21 15-38 34-38c19 0 34 17 34 38c0 21-15 38-34 38z"
                  fill="#5865f2"
                />
              </svg>
            </ListItemIcon>
            <ListItemText primary="Discord" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default AppMenu;

// https://ko-fi.com/stumblefoot
