import { Box, Modal, SxProps, Theme } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useAppSelector } from '../../app/hooks';
import AssetBox from '../asset/AssetBox';
import Banner from '../blueprint/Banner';
import { useCloseWorkerModal } from '../core/hooks';
import { workerSelectors } from '../data/dataSlice';

const style: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
  minWidth: 400,
  outline: 'none',
};

const paperStyle: SxProps<Theme> = {
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  mt: -5,
};

const WorkerModal = () => {
  const open = useAppSelector((state) => state.core.workerModal.open);
  const workerId = useAppSelector((state) => state.core.workerModal.workerId);
  const worker = useAppSelector((state) =>
    workerSelectors.selectById(state, state.core.workerModal.workerId || '')
  );
  const closeModal = useCloseWorkerModal();

  if (!worker) return null;

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
          top="-195px"
          sx={{
            mb: '-195px',
            pointerEvents: 'none',
          }}
        >
          <Box
            width="185px"
            height="185px"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              pointerEvents: 'auto',
              border: 4,
              borderRadius: '50%',
              backgroundColor: 'red',
              p: 0,
            }}
            position="relative"
          >
            <AssetBox src={worker.asset} width="200px" height="200px" />
          </Box>
          <Banner
            width="240px"
            typographySx={{ pt: 0 }}
            containerSx={{ mt: -2 }}
          >
            {worker.name}
          </Banner>
        </Box>
        <Paper sx={paperStyle}>
          <div>Worker Modal! {workerId}</div>
        </Paper>
      </Box>
    </Modal>
  );
};

export default WorkerModal;
