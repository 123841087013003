import { Box } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { blueprintSelector } from './BlueprintModal';
import BlueprintModalBlueprintStats from './BlueprintModalBlueprintStats';
import BlueprintModalBlueprintResources from './BlueprintModalBlueprintResources';
import BlueprintModalBlueprintWorkers from './BlueprintModalBlueprintWorkers';

const BlueprintModalBlueprint = () => {
  const bp = useAppSelector((s) => blueprintSelector(s, 'blueprint'));
  if (!bp) return null;

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <BlueprintModalBlueprintStats />
      <BlueprintModalBlueprintResources />
      <BlueprintModalBlueprintWorkers />
    </Box>
  );
};

export default BlueprintModalBlueprint;
