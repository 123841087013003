import {
  useGetMerchantMaxMarketTier,
  useGetWorkersWithUserData,
} from '../../app/hooks';
import { AssetBadgeProps } from '../asset/AssetBadge';
import { BlueprintWithCollection } from '../data/dataSlice';

interface UseGetAssetBadgePropsFn {
  (blueprint: BlueprintWithCollection | undefined): AssetBadgeProps;
}

export const useGetAssetBadgeProps: UseGetAssetBadgePropsFn = (blueprint) => {
  const merchantMaxMarketItemTier = useGetMerchantMaxMarketTier();
  const workers = useGetWorkersWithUserData();

  let assetBadgeProps: AssetBadgeProps = { showBadge: false, src: '' };
  if (blueprint) {
    if (blueprint.learned) {
      for (let i = 1; i <= 3; i++) {
        const requiredWorker = `requiredWorker${i}` as
          | 'requiredWorker1'
          | 'requiredWorker2'
          | 'requiredWorker3';
        const workerLevel = `workerLevel${i}` as
          | 'workerLevel1'
          | 'workerLevel2'
          | 'workerLevel3';

        const blueprintWorkerLevel = blueprint[workerLevel];
        const blueprintRequiredWorker = blueprint[requiredWorker];

        if (blueprintWorkerLevel && blueprintRequiredWorker) {
          const worker = workers.find(
            (w) =>
              w.worker === blueprintRequiredWorker &&
              w.level < blueprintWorkerLevel
          );
          if (worker) {
            assetBadgeProps = { showBadge: true, src: worker.asset };
          }
        }
      }
    } else {
      if (blueprint.tier > merchantMaxMarketItemTier) {
        assetBadgeProps = {
          showBadge: true,
          search: 'None',
          type: 'marketFilter',
        };
      } else {
        assetBadgeProps = {
          showBadge: true,
          search: 'Trade',
          type: 'miscIcons',
        };
      }
    }
  }
  return assetBadgeProps;
};
