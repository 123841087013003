import { Box } from '@mui/system';

import BlueprintModalCalculateAirshipPower from './BlueprintModalCalculateAirshipPower';
import BlueprintModalSelectQuality from './BlueprintModalSelectQuality';
import BlueprintModalSelectEnchantment from './BlueprintModalSelectEnchantment';
import { useAppSelector } from '../../app/hooks';
import { blueprintSelector } from './BlueprintModal';

const BlueprintModalAirshipPower = () => {
  const blueprint = useAppSelector((s) => blueprintSelector(s, 'blueprint'));

  return (
    <Box>
      <BlueprintModalCalculateAirshipPower />
      <Box display="flex" justifyContent="center" gap={2}>
        <BlueprintModalSelectEnchantment
          enchantmentType="element"
          affinity={blueprint?.elementAffinity}
          builtIn={blueprint?.builtInElement}
        />
        <BlueprintModalSelectEnchantment
          enchantmentType="spirit"
          affinity={blueprint?.spiritAffinity}
          builtIn={blueprint?.builtInSpirit}
        />
      </Box>
      <BlueprintModalSelectQuality />
    </Box>
  );
};

export default BlueprintModalAirshipPower;
