import { isEqual } from 'lodash';
import {
  IWorkerWithUserData,
  workerSelectors,
} from '../../features/data/dataSlice';
import {
  getNewUserWorker,
  userWorkerSelectors,
} from '../../features/user/userSlice';
import { useAppSelector } from './storeHooks';

export const useGetWorkersWithUserData = () => {
  return useAppSelector((state) => {
    return workerSelectors.selectAll(state).map((worker) => {
      const userWorker = userWorkerSelectors.selectById(state, worker.id);
      return {
        ...worker,
        ...(userWorker ?? getNewUserWorker(worker.id)),
      } as IWorkerWithUserData;
    });
  }, isEqual);
};
