import { camelCase, sortBy } from 'lodash';
import { combineArrays, getAsset } from './dataHelpers';
import {
  assetsPromise,
  kabamBlueprintPromise,
  kabamMerchantLevelPromise,
  nerileaPromise,
  workerLevelsPromise,
  workersPromise,
} from './dataPromises';
import {
  Asset,
  Blueprint,
  BlueprintClass,
  BlueprintType,
  IImportData,
} from './dataSlice';

export const fetchDataAPI = () => {
  return Promise.all([
    kabamBlueprintPromise(),
    kabamMerchantLevelPromise(),
    nerileaPromise(),
    assetsPromise(),
    workersPromise(),
    workerLevelsPromise(),
  ]).then(([kabam, kabamMerchant, nerilea, assets, workers, workerLevels]) => {
    const blueprints = combineArrays(kabam, nerilea, assets) as Blueprint[];

    const bpClasses: BlueprintClass[] = [];
    const bpTypes = blueprints.reduce((acc, bp) => {
      const existingType = acc.find((i) => i.name === bp.type);
      const existingClass = bpClasses.find((i) => i.name === bp.class);
      if (existingClass) {
        existingClass.count++;
      } else {
        bpClasses.push({
          name: bp.class,
          asset: getAsset(assets, bp.class, '/Filter Types/') || null,
          count: 1,
        });
      }

      if (existingType) {
        existingType.count++;
        return acc;
      }

      return [
        ...acc,
        {
          name: bp.type,
          class: bp.class,
          count: 1,
          asset: getAsset(assets, bp.type, '/Item Types/') || null,
        },
      ];
    }, [] as BlueprintType[]);

    const assetsMap: { type: Asset['type']; search: string }[] = [
      { type: 'chest', search: '/Chests/' },
      { type: 'component', search: '/Components/' },
      { type: 'currency', search: '/Currencies/' },
      { type: 'elementEnchanted', search: '/Elements/Enchantment/' },
      { type: 'elementAffinity', search: '/Elements/Affinity/' },
      { type: 'filterType', search: '/Filter Types/' },
      { type: 'heroClass', search: '/Hero Classes/' },
      { type: 'qualityIndicator', search: '/Custom/Quality Indicators/' },
      { type: 'resource', search: '/Resources/' },
      { type: 'spiritAffinity', search: '/Skills/Enchantment/' },
      { type: 'statIndicator', search: '/Stat Indicators/' },
      { type: 'tierFilter', search: '/Market Filters/Tier Filters/' },
      { type: 'marketFilter', search: '/Market Filters/' },
      { type: 'miscIcons', search: '/Misc Icons/' },
      { type: 'skillsLeader', search: '/Skills/Leader' },
      { type: 'skillsInnate', search: '/Skills/Innates' },
      { type: 'skillsHero', search: '/Skills/Hero' },
      { type: 'characterPortraits', search: '/Character Portraits/' },
      { type: 'blueprint', search: '/Items/' },
    ];

    const augmentedWorkers = workers.map((worker) => {
      const workerAsset = getAsset(
        assets,
        worker.name,
        '/Character Portraits/'
      );
      worker.id = camelCase(worker.worker + worker.name);
      // kElm.testId = camelCase('t' + kElm.tier + kElm.type + kElm.name);

      if (workerAsset) {
        worker.asset = workerAsset;
      }
      return worker;
    });
    const assetEntities = assets.reduce((acc, asset) => {
      assetsMap.forEach((assetMap) => {
        if (
          asset.asset &&
          asset.route.startsWith(assetMap.search) &&
          !asset.route.endsWith('_g.png') &&
          !asset.route.endsWith('_d.png')
        ) {
          acc.push({
            name: asset.asset,
            type: assetMap.type,
            asset: asset.route,
          });
          if (
            assetMap.type === 'spiritAffinity' &&
            !asset.asset.endsWith('Spirit')
          ) {
            acc.push({
              name: asset.asset.split(' ')[0] + ' Spirit',
              type: assetMap.type,
              asset: asset.route,
            });
          }
        }
      });

      return acc;
    }, [] as Asset[]);

    const classes = sortBy(bpClasses, (c) => c.name);
    const types = sortBy(bpTypes, (t) => t.name);

    return {
      blueprints,
      merchantLevels: kabamMerchant,
      types,
      classes,
      assets: assetEntities,
      workers: augmentedWorkers,
      workerLevels,
    };
  });
};

export const importDataAPI = (data: IImportData) => {
  return new Promise<IImportData>((resolve) => {
    resolve(data);
  });
};
