import { Box } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAppSelector,
  useCalculateBlueprintProperty,
  useGetBlueprintsWCFromSettings,
} from '../../app/hooks';
import CalculateCategoryEnchants from '../airship/CalculateCategoryEnchants';
import CalculateCategoryItemIcon from '../airship/CalculateCategoryItemIcon';
import { MRTFixedCellSize } from '../core/coreHelpers';
import { useOpenBlueprintModal } from '../core/hooks';
import { BlueprintWithCollection } from '../data/dataSlice';
import KingBaitTopToolbar from './KingBaitTopToolbar';

export interface IKingBait {
  id: EntityId;
  name: BlueprintWithCollection['name'];
  blueprint: BlueprintWithCollection;
  element: BlueprintWithCollection | undefined;
  spirit: BlueprintWithCollection | undefined;
  value: number;
}

const KingBait = () => {
  const openBlueprintModal = useOpenBlueprintModal();
  const calculateValue = useCalculateBlueprintProperty('value');
  const quality = useAppSelector((s) => s.user.kingBait?.quality || 'superior');
  const [timeUsed, setTimeUsed] = useState(0);
  const [count, setCount] = useState(0);
  const [kingBaits, setKingBaits] = useState<IKingBait[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const blueprints = useGetBlueprintsWCFromSettings('kingBait', 'item');
  const elements = useGetBlueprintsWCFromSettings('kingBait', 'element');
  const spirits = useGetBlueprintsWCFromSettings('kingBait', 'spirit');

  const doCalculations = useCallback(() => {
    const startTime = new Date().getTime();
    const calculatedKingBaits: IKingBait[] = [];

    for (const blueprint of blueprints) {
      for (const element of [...elements, undefined]) {
        for (const spirit of [...spirits, undefined]) {
          if (
            (blueprint.builtInSpirit && spirit) ||
            (blueprint.builtInElement && element)
          ) {
            continue;
          }
          const value = calculateValue(blueprint, quality, element, spirit);

          calculatedKingBaits.push({
            id: blueprint.id,
            name: blueprint.name,
            blueprint,
            element,
            spirit,
            value,
          });
        }
      }
    }
    calculatedKingBaits.sort((a, b) => b.value - a.value);

    const uniqueKingBaits = calculatedKingBaits.reduce((unique, o) => {
      if (!unique.some((obj) => obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, [] as IKingBait[]);

    const endTime = new Date().getTime();
    const timeUsed = endTime - startTime;

    setIsLoading(false);
    setCount(calculatedKingBaits.length);
    setTimeUsed(timeUsed);
    setKingBaits(uniqueKingBaits);
  }, [blueprints, calculateValue, elements, quality, spirits]);

  useEffect(() => {
    doCalculations();
  }, [doCalculations]);

  const columns = useMemo<MRT_ColumnDef<IKingBait>[]>(
    () => [
      {
        id: 'asset',
        header: 'Icon',
        accessorKey: 'blueprint.asset',
        Cell: ({ row }) => <CalculateCategoryItemIcon id={row.original.id} />,
        size: 60,
        ...MRTFixedCellSize(),
      },
      {
        header: 'T',
        accessorKey: 'blueprint.tier',
        size: 40,
        ...MRTFixedCellSize({ align: 'right' }),
      },
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Enchants',
        Cell: ({ row }) => (
          <CalculateCategoryEnchants
            airshipPower={{
              id: row.original.id,
              name: row.original.name,
              blueprint: row.original.blueprint,
              element: row.original.element,
              spirit: row.original.spirit,
            }}
            elements={elements}
            spirits={spirits}
          />
        ),
        size: 105,
        ...MRTFixedCellSize(),
      },

      {
        header: 'Value',
        accessorKey: 'value',
        Cell: ({ cell }) => <>{cell.getValue<number>().toLocaleString()}</>,
        size: 140,
        ...MRTFixedCellSize({ align: 'right' }),
      },
    ],
    [elements, spirits]
  );

  if (isLoading) return <Box>Loading...</Box>;

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={kingBaits}
        layoutMode="grid"
        initialState={{
          density: 'compact',
          pagination: {
            pageSize: 10,
            pageIndex: 0,
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            const elementId = row.original.element?.id;
            const spiritId = row.original.spirit?.id;
            openBlueprintModal(
              row.original.id,
              'airship',
              elementId,
              spiritId,
              quality
            );
          },
          sx: { cursor: 'pointer' },
        })}
        muiPaginationProps={{
          showRowsPerPage: false,
        }}
        enableColumnResizing={false}
        enableToolbarInternalActions={false}
        enableTableHead={false}
        muiTopToolbarProps={{ sx: { borderBottom: 1, borderColor: 'divider' } }}
        renderTopToolbarCustomActions={() => <KingBaitTopToolbar />}
      />
      <Box>
        Performed {count.toLocaleString()} permutations in {timeUsed}ms
      </Box>
    </Box>
  );
};

export default KingBait;
