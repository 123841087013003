import { Blueprint } from '../../features/data/dataSlice';
import { PropSearch, useGetBlueprintsSearch } from '.';

interface UseGetBlueprintSearch {
  (
    propSearches: PropSearch<keyof Blueprint>[],
    learned?: boolean,
    operator?: 'AND' | 'OR'
  ): Blueprint;
}

const useGetBlueprintSearch: UseGetBlueprintSearch = (
  propSearches,
  learned,
  operator = 'AND'
) => {
  const blueprints = useGetBlueprintsSearch(propSearches, learned, operator);

  return blueprints[0];
};

export { useGetBlueprintSearch };
