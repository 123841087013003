import { Box } from '@mui/material';
import { get } from 'lodash';
import { Checkboxes } from 'mui-rff';
import { FormSpy } from 'react-final-form';
import Slider from '../../app/Slider';
import TierSlider from './TierSlider';

const settingsSections = [
  {
    label: 'Items',
    key: 'item',
    tier: 'settings.marketTier[blueprint]',
  },
  {
    label: 'Elements',
    key: 'element',
  },
  {
    label: 'Spirits',
    key: 'spirit',
  },
];

interface ISettingsFormProps {
  name: string;
  pageSizeName?: string;
}

const SettingsForm: React.FC<ISettingsFormProps> = ({ name, pageSizeName }) => {
  const marks: { value: number; label: string }[] = [];
  for (let i = 1; i <= 10; i++) {
    marks.push({ value: i, label: i.toString() });
  }

  return (
    <FormSpy>
      {({ values }) => (
        <Box display="flex" gap={3} flexDirection="column">
          {settingsSections.map(({ label, key }) => (
            <Box key={key} display="flex" flexDirection="column">
              <Box component="h4" mb={0.5} mt={0}>
                {label}
              </Box>
              <TierSlider
                name={`${name}.${key}.learnedTier`}
                label="Learned tier Limit"
              />
              <Checkboxes
                name={`${name}.${key}.includeNotlearned`}
                data={{ label: 'Include not learned?', value: key }}
              />
              <TierSlider
                disabled={!get(values, `${name}.${key}.includeNotlearned`)}
                name={`${name}.${key}.notlearnedTier`}
                label="Limit not learned tiers"
              />
            </Box>
          ))}
          {pageSizeName && (
            <Box display="flex" flexDirection="column">
              <Box component="h4" mb={0.5} mt={0}>
                Pagination
              </Box>
              <Slider
                name={`${pageSizeName}`}
                label="Rows per page"
                step={1}
                min={1}
                max={10}
                marks={marks}
                sx={{
                  ml: 1,
                  width: 'calc(100% - 10px)',
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </FormSpy>
  );
};

export default SettingsForm;
