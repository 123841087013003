import { MigrationManifest, PersistState } from 'redux-persist';
import dataReducer from '../../features/data/dataSlice';
import userReducer from '../../features/user/userSlice';
import { DataInitialState } from '../../features/data/dataSlice';
import storage from 'redux-persist/lib/storage';

export const persistedState = <T>(key: string) => {
  let state: any = {};
  const rawData = localStorage.getItem(key);
  if (rawData) {
    const rawState = JSON.parse(rawData);
    Object.keys(rawState).forEach((key) => {
      state[key] = JSON.parse(rawState[key]);
    });
    return state as T & { _persist: PersistState };
  }
  return undefined;
};

type UserState = ReturnType<typeof userReducer>;
type DataState = ReturnType<typeof dataReducer>;
type LegacyBlueprintState = {
  ids: DataState['blueprints']['ids'];
  entities: DataState['blueprints']['entities'];
  types: DataState['blueprintTypes'];
  classes: DataState['blueprintClasses'];
};
type LegacyAssetState = {
  assets: DataState['assets'];
};

export const dataMigrations: MigrationManifest = {
  1: (prevState: any) => {
    const blueprints =
      persistedState<LegacyBlueprintState>('persist:blueprint');
    const assets = persistedState<LegacyAssetState>('persist:asset');

    if (blueprints && assets) {
      storage.removeItem('persist:asset');
      storage.removeItem('persist:blueprint');
      const newState: DataInitialState = {
        ...prevState,
        assets: assets.assets,
        blueprints: {
          ids: blueprints.ids,
          entities: blueprints.entities,
        },
        blueprintTypes: blueprints.types,
        blueprintClasses: blueprints.classes,
      };
      return newState;
    }

    return prevState;
  },
};

export const userMigrations: MigrationManifest = {
  1: (prevState: any) => {
    console.log('user migration 0');

    const collections =
      persistedState<UserState['collection']>('persist:collection');

    if (collections) {
      storage.removeItem('persist:collection');
      const newState = {
        ...prevState,
        collection: {
          ids: collections.ids,
          entities: collections.entities,
        },
      };
      return newState;
    }

    return prevState;
  },
};
