export const globalDebug = (function () {
  // Save a reference to the original console object
  const savedConsole = console;

  return function (debugOn: boolean) {
    if (debugOn === false) {
      const consoleMethods = Object.getOwnPropertyNames(console);

      consoleMethods.forEach((method) => {
        (console as any)[method] = function () {};
      });
    } else {
      // eslint-disable-next-line no-global-assign
      console = savedConsole;
    }
  };
})();
