import { useAppSelector } from '.';

export const useGetMaxMarketTier = () => {
  return useAppSelector((state) =>
    state.data.merchantLevels.reduce(
      (max, item) => (item.tierUnlock > max ? item.tierUnlock : max),
      0
    )
  );
};
