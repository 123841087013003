import React from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { MRT_TableInstance } from 'material-react-table';

import { Blueprint } from '../data/dataSlice';
import { useAppSelector, useGetCurrentMode } from '../../app/hooks';
import { useCallback } from 'react';

type Props = {
  table: MRT_TableInstance<Blueprint>;
};

const filteredSx = {
  '&': { paddingRight: '4px' },
  '& .MuiSelect-outlined.MuiSelect-select': { paddingRight: '12px' },
  '& .MuiSelect-iconOutlined': { display: 'none' },
};

const BlueprintTableColumnFilters: React.FC<Props> = ({ table }) => {
  const tableState = table.getState();
  const columnFilters = tableState.columnFilters;
  const mode = useGetCurrentMode();

  const bpTypes = useAppSelector((state) => state.data.blueprintTypes);
  const typeFilter = tableState.columnFilters.find((cf) => cf.id === 'type');
  const filteredType = (typeFilter ? typeFilter?.value : '') as string;

  // const bpClasses = useAppSelector((state) => state.data.blueprintClasses);
  // const classFilter = tableState.columnFilters.find((cf) => cf.id === 'class');
  // const filteredClass = (classFilter ? classFilter?.value : '') as string;

  const handleChange = useCallback(
    (event: SelectChangeEvent, id: string) => {
      const foundFilter = columnFilters.find((cf) => cf.id === id);

      table.setPageIndex(0);
      if (foundFilter) {
        const newColumnFilter = columnFilters.map((cf) => {
          if (cf.id === id) {
            return {
              ...cf,
              value: event.target.value,
            };
          }
          return cf;
        });
        table.setColumnFilters(newColumnFilter);
      } else {
        const newColumnFilter = columnFilters.concat({
          id,
          value: event.target.value,
        });
        table.setColumnFilters(newColumnFilter);
      }
    },
    [columnFilters, table]
  );

  const handleDeselect = (id: string) => {
    table.setColumnFilters(columnFilters.filter((cf) => cf.id !== id));
  };

  return (
    <Box display="flex" gap="10px" flexWrap="wrap">
      {/* <FormControl size="small" sx={{ minWidth: 160, display: 'none' }}>
        <InputLabel size="small">Class</InputLabel>
        <Select
          onChange={(e) => handleChange(e, 'class')}
          size="small"
          placeholder="Select item class"
          label="Item class"
          value={filteredClass}
          defaultValue=""
          sx={{
            ...(filteredClass ? filteredSx : {}),
            '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' },
          }}
          endAdornment={
            filteredClass ? (
              <IconButton
                onClick={() => handleDeselect('class')}
                size="small"
                sx={{
                  position: 'relative',
                  ...(filteredClass ? {} : { display: 'none' }),
                }}
              >
                <ClearIcon />
              </IconButton>
            ) : (
              false
            )
          }
        >
          {bpClasses.map((bc) => {
            return (
              <MenuItem key={bc.name} value={bc.name}>
                <Box display="flex" alignItems="center">
                  <Box
                    component="img"
                    sx={{
                      widht: 20,
                      height: 20,
                      marginRight: '8px',
                      filter: 'invert(100%)',
                    }}
                    src={bc.asset || ''}
                    alt="Item class icon"
                  />
                  {`${bc.name} (${bc.count})`}
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl> */}
      <FormControl
        size="small"
        sx={{ minWidth: 120, maxWidth: 'calc(100vw - 230px)' }}
      >
        <InputLabel size="small">Type</InputLabel>
        <Select
          onChange={(e) => handleChange(e, 'type')}
          size="small"
          placeholder="Select item type"
          label="Item type"
          value={filteredType}
          defaultValue=""
          sx={{
            ...(filteredType ? filteredSx : {}),
            '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' },
          }}
          endAdornment={
            filteredType ? (
              <IconButton
                onClick={() => handleDeselect('type')}
                size="small"
                sx={{
                  visibility: filteredType ? 'visible' : 'hidden',
                }}
              >
                <ClearIcon />
              </IconButton>
            ) : (
              false
            )
          }
        >
          {bpTypes.map((bt) => {
            return (
              <MenuItem key={bt.name} value={bt.name}>
                <Box display="flex" alignItems="center">
                  <Box
                    component="img"
                    sx={{
                      widht: 20,
                      height: 20,
                      marginRight: '8px',
                      filter: mode === 'light' ? 'invert(100%)' : '',
                    }}
                    src={bt.asset || ''}
                    alt="Item type icon"
                  />
                  {`${bt.name} (${bt.count})`}
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BlueprintTableColumnFilters;
