import { ListItemIcon, ListItemText, Menu } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setThemModeAsync, UserInitialState } from '../user/userSlice';
import { capitalize } from 'lodash';

const getIcon = (mode: UserInitialState['mode']) => {
  switch (mode) {
    case 'system':
      return <SettingsBrightnessIcon />;
    case 'light':
      return <LightModeIcon />;
    case 'dark':
      return <DarkModeIcon />;
  }
};

const modes: UserInitialState['mode'][] = ['light', 'system', 'dark'];

const SetThemeMode = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const selectedMode = useAppSelector((state) => state.user.mode);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (mode: UserInitialState['mode']) => {
    dispatch(setThemModeAsync(mode));
    handleClose();
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        {getIcon(selectedMode)}
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {modes.map((mode) => (
          <MenuItem
            key={mode}
            onClick={() => onSelect(mode)}
            selected={mode === selectedMode}
          >
            <ListItemIcon>{getIcon(mode)}</ListItemIcon>
            <ListItemText>{capitalize(mode)}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SetThemeMode;
