import axios from 'axios';
import { camelCase } from 'lodash';
import Papa from 'papaparse';
import {
  AssetsData,
  cleanObject,
  cleanObjectsInArray,
  NerileaData,
  transform,
} from './dataHelpers';
import { Blueprint, MerchantLevel, IWorker, IWorkerLevel } from './dataSlice';

// Kabam Blueprints
const kabamBlueprintUrl =
  'https://docs.google.com/spreadsheets/d/1WLa7X8h3O0-aGKxeAlCL7bnN8-FhGd3t7pz2RCzSg8c/pub?gid=1558235212&output=csv';

// kabam Merchant Levels
const kabamMerchantLevelUrl =
  'https://docs.google.com/spreadsheets/d/1WLa7X8h3O0-aGKxeAlCL7bnN8-FhGd3t7pz2RCzSg8c/pub?gid=1602631852&output=csv';

// kabam Collection Book
const kabamCollectionBookUrl =
  'https://docs.google.com/spreadsheets/d/1WLa7X8h3O0-aGKxeAlCL7bnN8-FhGd3t7pz2RCzSg8c/pub?gid=805699567&output=csv';

// Nerilea
const nerileaUrl =
  'https://docs.google.com/spreadsheets/d/e/2PACX-1vTLObUYiJbFeuWeGuhAIwXFDjDPiAPIqsvAwem6nKqtZ0BKRsrfRtW6uCPK4eBzpoffG7ciY9GvNHQs/pub?output=csv';

// Fan Kit Assets
const assetsUrl =
  'https://docs.google.com/spreadsheets/d/1T-cfT73u0zweSsr4Nhbwzz7Ymkc_r8ArypAGh8M6d8c/pub?gid=142395081&output=csv';

// Workers
const workersUrl =
  'https://docs.google.com/spreadsheets/d/1WLa7X8h3O0-aGKxeAlCL7bnN8-FhGd3t7pz2RCzSg8c/pub?gid=1935922361&output=csv';

// worker levels
const workerLevelsUrl =
  'https://docs.google.com/spreadsheets/d/1WLa7X8h3O0-aGKxeAlCL7bnN8-FhGd3t7pz2RCzSg8c/pub?gid=370031681&output=csv';

export const kabamBlueprintPromise = () =>
  new Promise<Blueprint[]>((resolve) => {
    const duplicateHeaders: { [key: string]: number } = {
      requiredWorker: 1,
      workerLevel: 1,
      component: 1,
      componentQuality: 1,
      amountNeeded: 1,
      craftsNeeded: 1,
      shardsNeeded: 1,
    };

    Papa.parse<Blueprint>(kabamBlueprintUrl, {
      download: true,
      dynamicTyping: true,
      complete: (results) => {
        const blueprints = results.data.map((bp) => {
          if (bp.type === 'Enchantment') {
            if (bp.name.toLowerCase().includes('spirit')) {
              bp.type = 'Spirit';
            }
            if (bp.name.toLowerCase().includes('element')) {
              bp.type = 'Element';
            }
          }
          return cleanObject(bp);
        });
        resolve(blueprints);
      },
      header: true,
      transformHeader: (header, hIndex) => {
        let camelHeader = camelCase(header);
        if (duplicateHeaders[camelHeader]) {
          duplicateHeaders[camelHeader]++;
          camelHeader = `${camelHeader}${duplicateHeaders[camelHeader] - 1}`;
        }
        switch (camelHeader) {
          case 'elementalAffinity':
            return 'elementAffinity';
        }
        switch (hIndex) {
          case 24:
            return 'iron';
          case 25:
            return 'wood';
          case 26:
            return 'leather';
          case 27:
            return 'herbs';
          case 28:
            return 'steel';
          case 29:
            return 'ironwood';
          case 30:
            return 'fabric';
          case 31:
            return 'oils';
          case 32:
            return 'mana';
          case 33:
            return 'gems';
          case 34:
            return 'essence';
          default:
            return camelCase(camelHeader);
        }
      },
      transform,
    });
  });

export const kabamMerchantLevelPromise = () =>
  new Promise<MerchantLevel[]>((resolve) => {
    Papa.parse<MerchantLevel>(kabamMerchantLevelUrl, {
      download: true,
      dynamicTyping: true,
      header: true,
      transformHeader: (header, hIndex) => {
        const camelHeader = camelCase(header);
        switch (camelHeader) {
          case 'merchantLevel':
            return 'level';
          case 'marketItemTierUnlock':
            return 'tierUnlock';
          default:
            return camelHeader;
        }
      },
      complete: (results) => {
        resolve(cleanObjectsInArray(results.data));
      },
      transform,
    });
  });

export const nerileaPromise = () =>
  new Promise<NerileaData[]>((resolve) => {
    Papa.parse<NerileaData>(nerileaUrl, {
      download: true,
      dynamicTyping: true,
      complete: (results) => {
        resolve(cleanObjectsInArray(results.data));
      },
      header: true,
      transformHeader: (header, hIndex) => {
        const camelHeader = camelCase(header);
        switch (camelHeader) {
          case 'elementalAffinity':
            return 'elementAffinity';
          case 'attack':
            return 'atk';
          case 'defense':
            return 'def';
          case 'health':
            return 'hp';
          case 'evasion':
            return 'eva';
          case 'criticalHitChance':
            return 'crit';
        }
        return camelHeader;
      },
      transform,
    });
  });

export const assetsPromise = () =>
  new Promise<AssetsData[]>((resolve) => {
    Papa.parse<AssetsData>(assetsUrl, {
      download: true,
      dynamicTyping: true,
      complete: (results) => {
        resolve(results.data);
      },
      header: true,
      transformHeader: (header, hIndex) => {
        return camelCase(header);
      },
    });
  });

export const currentTimePromise = () =>
  new Promise<number>((resolve, reject) => {
    axios
      .get('https://worldtimeapi.org/api/timezone/Europe/Copenhagen')
      .then((response) => {
        resolve(response.data.unixtime);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const workersPromise = () =>
  new Promise<IWorker[]>((resolve) => {
    Papa.parse<IWorker>(workersUrl, {
      download: true,
      dynamicTyping: true,
      complete: (results) => {
        resolve(cleanObjectsInArray(results.data));
      },
      header: true,
      transformHeader: (header, hIndex) => {
        return camelCase(header);
      },
      transform,
    });
  });

export const workerLevelsPromise = () =>
  new Promise<IWorkerLevel[]>((resolve) => {
    Papa.parse<IWorkerLevel>(workerLevelsUrl, {
      download: true,
      dynamicTyping: true,
      complete: (results) => {
        resolve(cleanObjectsInArray(results.data));
      },
      header: true,
      transformHeader: (header, hIndex) => {
        const camelHeader = camelCase(header);
        switch (camelHeader) {
          case 'workerLevel':
            return 'level';
          case 'craftingSpeedBonus':
            return 'speedBonus';
          case 'xpNeeded':
            return 'xp';
          default:
            return camelHeader;
        }
      },
      transform: (value, header) => {
        const newValue = transform(value, header);
        if (newValue && header === 'speedBonus') {
          return Number(newValue.replace('%', ''));
        }
        return newValue;
      },
    });
  });
