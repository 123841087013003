import { Typography } from '@mui/material';
import { useAppSelector, useShowCollectionSelector } from '../../app/hooks';
import CollectionTableCellLearned from '../collection/CollectionTableCellLearned';
import CollectionTableCellMastered from '../collection/CollectionTableCellMastered';
import CollectionTableCollected from '../collection/CollectionTableCollected';
import { blueprintSelector } from './BlueprintModal';

const BlueprintModalCollection = () => {
  const bp = useAppSelector((s) => blueprintSelector(s, 'blueprint'));
  const showCollection = useShowCollectionSelector(bp?.id);

  if (!bp) return null;

  return (
    <>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Learned <CollectionTableCellLearned id={bp.id} />
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Mastered <CollectionTableCellMastered id={bp.id} />
      </Typography>
      {showCollection ? (
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Collection <CollectionTableCollected id={bp.id} />
        </Typography>
      ) : null}
    </>
  );
};

export default BlueprintModalCollection;
