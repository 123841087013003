import { Undefinable } from 'tsdef';
import AssetBox from '../asset/AssetBox';
import { Asset } from '../data/dataSlice';
import { Blueprint } from '../data/dataSlice';

type Props = {
  enchantType: 'Element' | 'Spirit';
  enchant: Undefinable<Blueprint>;
  isUnique: boolean;
  blueprint: Blueprint;
};

const size = { width: '35px', height: '35px' };

const CalculateCategoryItemEnchant: React.FC<Props> = ({
  enchantType,
  enchant,
  isUnique,
  blueprint,
}) => {
  const builtIn = `builtIn${enchantType}` as 'builtInElement' | 'builtInSpirit';
  const assetType =
    enchantType === 'Element' ? 'elementEnchanted' : 'spiritAffinity';

  const bpBuiltIn = blueprint[builtIn];
  if (bpBuiltIn) {
    return (
      <AssetBox
        {...size}
        search={bpBuiltIn}
        type={assetType}
        shadow="builtin"
      />
    );
  }

  const affinityType =
    enchantType === 'Element' ? 'elementAffinity' : 'spiritAffinity';
  const affinityMatch =
    enchant && blueprint[affinityType] === enchant[affinityType];

  const asset =
    affinityMatch || isUnique
      ? enchant?.assetEnchantment
      : `/assets/Custom/icon_global_${enchantType.toLowerCase()}_all.png`;

  const overlay = !enchant
    ? {
        src: '/assets/Market Filters/img_none_selected.png',
        sx: { width: '25px', height: '25px' },
      }
    : affinityMatch || isUnique
    ? undefined
    : {
        type: 'tierFilter' as Asset['type'],
        search: `Tier ${enchant?.tier} Selected`,
      };

  return asset ? (
    <AssetBox
      {...size}
      src={asset}
      shadow={
        affinityMatch || blueprint[affinityType] === 'All' ? 'affinity' : false
      }
      overlay={overlay}
    />
  ) : null;
};

export default CalculateCategoryItemEnchant;
