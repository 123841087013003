import React from 'react';
import { Box, Checkbox, CheckboxProps } from '@mui/material';
import { EntityId } from '@reduxjs/toolkit';

import { useGetCollection } from '../../app/hooks/useGetCollection';
import { useAppDispatch } from '../../app/hooks';
import {
  CollectedQualityTypes,
  collectionToggleQualityCollected,
} from '../user/userSlice';
import { useShowCollectionSelector } from '../../app/hooks/useShowCollectionSelector';

type Props = {
  id: EntityId;
};

const useHandleToggleQuality = (id: EntityId) => {
  const dispatch = useAppDispatch();
  return (quality: CollectedQualityTypes) => {
    dispatch(collectionToggleQualityCollected(id, quality));
  };
};

type Quality = {
  name: CollectedQualityTypes;
  color?: CheckboxProps['color'];
  asset: string;
  multiplier: number;
  valueMultiplier: number;
};

export const blueprintQualities: Quality[] = [
  {
    name: 'normal',
    color: 'qualityNormal',
    asset: '/assets/Custom/Quality Indicators/icon_global_quality_common.png',
    multiplier: 1,
    valueMultiplier: 1,
  },
  {
    name: 'superior',
    color: 'qualitySuperior',
    asset: '/assets/Custom/Quality Indicators/icon_global_quality_uncommon.png',
    multiplier: 1.25,
    valueMultiplier: 1.25,
  },
  {
    name: 'flawless',
    color: 'qualityFlawless',
    asset: '/assets/Custom/Quality Indicators/icon_global_quality_flawless.png',
    multiplier: 1.5,
    valueMultiplier: 2,
  },
  {
    name: 'epic',
    color: 'qualityEpic',
    asset: '/assets/Custom/Quality Indicators/icon_global_quality_epic.png',
    multiplier: 2,
    valueMultiplier: 3,
  },
  {
    name: 'legendary',
    color: 'qualityLegendary',
    asset:
      '/assets/Custom/Quality Indicators/icon_global_quality_legendary.png',
    multiplier: 3,
    valueMultiplier: 5,
  },
];

const CollectionTableCollected: React.FC<Props> = ({ id }) => {
  const collection = useGetCollection(id);
  const showCollection = useShowCollectionSelector(id);
  const handleToggleQuality = useHandleToggleQuality(id);

  if (!showCollection) return null;

  const { learned, mastered, collected } = collection;
  const disabled = !learned || !mastered;

  return (
    <>
      {blueprintQualities.map((q) => {
        return (
          <Checkbox
            key={q.name}
            disabled={disabled}
            checked={collected[q.name]}
            color={q.color}
            onChange={() => handleToggleQuality(q.name)}
            onClick={(e) => e.stopPropagation()}
            checkedIcon={
              <Box
                component="img"
                src={q.asset}
                sx={{ width: '24px', height: '24px' }}
              />
            }
            icon={
              <Box
                component="img"
                src={q.asset}
                sx={{
                  width: '24px',
                  height: '24px',
                  filter: 'grayscale(55%) opacity(25%)',
                }}
              />
            }
          />
        );
      })}
    </>
  );
};

export default CollectionTableCollected;

export const MemoizedCollectionTableCollected = React.memo(
  CollectionTableCollected
);
