const KingsCaprice = () => {
  return <div>King's Caprice</div>;
};

export default KingsCaprice;

/*
Multiplicative:

EG. Ninja Garb original craft time 7800sec

Given data: Milestone 25%; Lv24 Tailor 34%; Lv29 Master 42%; Fast Craft Perk 15%

Formula: 7800s * (100%-25%) * (100%-34%) * (100%-42%) * (100%-15%)

= 7800s * 75% * 66% * 58% * 85% = 1903.473 approximate (in my game it shows 1902s)

Hope that helps.
// */
